import {
  useDepistagesStore,
  useFunnelStore,
  useQuestionnaireStore,
  useVerticalsStore,
} from "@bleu/front/lib/stores"

export enum Steps {
  start = 0,
  profile = 1,
  depistagesSelection = 2,
  questionnaires = 3,
  recap = 4,
  payment = 5,
}

export const stepNames = [
  "Début",
  "Profil",
  "Dépistages", // Shortened from "Sélection des dépistages"
  "Questionnaires",
  "Récapitulatif",
  "Paiement",
]

export * from "./useDepistages"
export * from "./useFunnelEffects"
export * from "./useStepNavigation"
export * from "./useStepNavigation"

export const resetAllStores = () => {
  useFunnelStore.getState().reset()
  useQuestionnaireStore.getState().reset()
  useVerticalsStore.getState().reset()
  useDepistagesStore.getState().reset()
}
