/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { logger } from "@bleu/front/utils/logger"

import { stepNames, Steps } from "./index"

/**
 * Custom hook that manages the effects associated with the current funnel step.
 *
 * @param currentStep - The current step in the funnel.
 * @param handleNext - Function to handle navigation to the next step.
 */
export const useFunnelEffects = (
  currentStep: Steps,
  handleNext: () => void,
) => {
  const {
    setHeader,
    setTitle,
    setCta,
    setShowBackButton,
    setStepNames,
    setCurrentStep: setCurrentStepLayout,
    setTotalSteps,
  } = useQuestionnaireLayoutStore()

  useEffect(() => {
    setShowBackButton(currentStep !== Steps.start)
    logger.info("SetShowBackButton in useFunnelEffects")

    if (currentStep === Steps.start) {
      setCta({
        onClick: handleNext,
        isDisabled: false,
        text: "Continuer",
      })
      logger.info(`CTA set for step ${currentStep}`)
    }

    if (currentStep === Steps.recap || currentStep === Steps.payment) {
      setCta({
        onClick: handleNext,
        isDisabled: false,
        text: currentStep === Steps.recap ? "Payer" : "Terminer",
      })
      logger.info(`CTA set for step ${currentStep}`)
    }
  }, [currentStep, setShowBackButton, setCta, handleNext])

  useEffect(() => {
    setStepNames(stepNames)
    logger.info(`Step names set: ${stepNames.join(", ")}`)
    switch (currentStep) {
      case Steps.start:
        setHeader({ pageName: "Début", progress: 0 })
        setTitle("Commencer le questionnaire")
        setCurrentStepLayout(Steps.start)
        setTotalSteps(stepNames.length)
        logger.info("Funnel started")
        break
      case Steps.profile:
        setHeader({ pageName: "Profil", progress: 20 })
        setTitle("Complétez votre profil")
        setCurrentStepLayout(Steps.profile)
        setTotalSteps(stepNames.length)
        logger.info("Profile step setup")
        break
      case Steps.depistagesSelection:
        setHeader({ pageName: "Sélection des dépistages", progress: 40 })
        setTitle("Sélectionnez vos dépistages")
        setCurrentStepLayout(Steps.depistagesSelection)
        setTotalSteps(stepNames.length)
        logger.info("Depistages selection step setup")
        break
      case Steps.questionnaires:
        setHeader({ pageName: "Questionnaires", progress: 60 })
        setTitle("Répondez aux questions")
        setCurrentStepLayout(Steps.questionnaires)
        setTotalSteps(stepNames.length)
        logger.info("Questionnaires step setup")
        break
      case Steps.recap:
        setHeader({ pageName: "Récapitulatif", progress: 80 })
        setTitle("Vérifiez vos réponses")
        setCurrentStepLayout(Steps.recap)
        setTotalSteps(stepNames.length)
        logger.info("Recap step setup")
        break
      case Steps.payment:
        setHeader({ pageName: "Paiement", progress: 100 })
        setTitle("Finalisez votre commande")
        setCurrentStepLayout(Steps.payment)
        setTotalSteps(stepNames.length)
        logger.info("Payment step setup")
        break
    }
  }, [currentStep])
}
