import { create } from "zustand"

interface LayoutState {
  title: string | { text: string; highlight?: string }
  pageName?: string
  progress?: number
  showBackButton: boolean
  nextButtonText: string
  onBack?: () => void
  onNext?: () => void
  setTitle: (title: string | { text: string; highlight?: string }) => void
  setPageName: (pageName?: string) => void
  setProgress: (progress?: number) => void
  setShowBackButton: (show: boolean) => void
  setNextButtonText: (text: string) => void
  setOnBack: (callback?: () => void) => void
  setOnNext: (callback?: () => void) => void
}

export const useLayoutStore = create<LayoutState>((set) => ({
  title: "",
  pageName: undefined,
  progress: undefined,
  showBackButton: true,
  nextButtonText: "Continuer",
  onBack: undefined,
  onNext: undefined,
  setTitle: (title) => set({ title }),
  setPageName: (pageName) => set({ pageName }),
  setProgress: (progress) => set({ progress }),
  setShowBackButton: (show) => set({ showBackButton: show }),
  setNextButtonText: (text) => set({ nextButtonText: text }),
  setOnBack: (callback) => set({ onBack: callback }),
  setOnNext: (callback) => set({ onNext: callback }),
}))
