import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react"
import React from "react"

import { colors } from "@bleu/front/assets/theme/colors"
import type { QuestionProps } from "@bleu/front/components/forms/types"

import { QuestionWrapper } from "./QuestionWrapper"

interface NumberQuestionProps extends QuestionProps<number> {
  unit?: string
  title: string
  subtitle?: string
}

export const NumberQuestion = ({
  title,
  subtitle,
  value,
  unit,
  onChange,
  error,
  ...props
}: NumberQuestionProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault()
    }
  }

  return (
    <QuestionWrapper title={title} subtitle={subtitle}>
      <FormControl isInvalid={!!error} width="100%">
        <InputGroup>
          <Input
            type="number"
            value={value ?? ""}
            border={`1px solid ${colors.blue[300]}`}
            borderRadius={4}
            width="100%"
            onKeyDown={handleKeyDown}
            {...props}
            onChange={(e) => onChange(parseFloat(e.target.value))}
          />
          {unit && (
            <InputRightAddon border={`1px solid ${colors.blue[300]}`}>
              {unit}
            </InputRightAddon>
          )}
        </InputGroup>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </QuestionWrapper>
  )
}
