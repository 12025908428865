import {
  Checkbox,
  Text,
  VStack,
  Link as ChakraLink,
  Container,
} from "@chakra-ui/react"
import { useUser } from "@clerk/clerk-react"
import { useForm, zodResolver } from "@mantine/form"
import { useCallback, useEffect } from "react"
import { Link } from "react-router-dom"
import { z } from "zod"

import {
  BiologicalSexOption,
  profileQuestionnaire,
  questionnaires,
  type ProfileData,
} from "@bleu/shared/questionnaires"

import { Question } from "@bleu/front/components/funnelSteps/Questionnaires/Questionnaire"
import { useProfileStore } from "@bleu/front/lib/stores"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"

interface Props {
  profile?: Partial<ProfileData>
  onSubmit: (profile: ProfileData, acceptsMarketing: boolean) => void
  handleNext: () => void
}

export const ProfileQuestionnaire = ({
  profile,
  onSubmit,
  handleNext,
}: Props) => {
  const { user } = useUser()
  const { questions } = questionnaires.profile
  const {
    setProfile,
    setCgu,
    setAcceptsMarketing,
    cgu: storedCgu,
    acceptsMarketing: storedAcceptsMarketing,
  } = useProfileStore()
  const { setHeader, setTitle, setCta, setShowBackButton } =
    useQuestionnaireLayoutStore()

  const form = useForm<{
    profile: ProfileData
    cgu: boolean
    acceptsMarketing: boolean
  }>({
    initialValues: {
      // @ts-expect-error TODO FIXME: Cannot manage to make it compile
      profile: {
        ...profileQuestionnaire.initialValues,
        ...(profile as Partial<ProfileData>),
        biologicalSex: profile?.biologicalSex ?? BiologicalSexOption.FEMALE,
      },
      cgu: user ? true : storedCgu,
      acceptsMarketing: user ? false : storedAcceptsMarketing,
    },
    validate: zodResolver(
      z.object({
        profile: profileQuestionnaire.schema,
        ...(!user && { cgu: z.literal(true), acceptsMarketing: z.boolean() }),
      }),
    ),
    validateInputOnChange: ["profile.age", "profile.weight", "profile.height"],
    onValuesChange: (values) => {
      setProfile(values.profile)
      if (!user) {
        setCgu(values.cgu)
        setAcceptsMarketing(values.acceptsMarketing)
      }
    },
  })

  const isDisabled = !form.isValid()

  const handleClick = useCallback(() => {
    const profile = profileQuestionnaire.schema.parse(form.values.profile)
    setProfile(profile)
    onSubmit(profile, form.values.acceptsMarketing)
    handleNext()
  }, [
    form.values.profile,
    form.values.acceptsMarketing,
    onSubmit,
    setProfile,
    handleNext,
  ])

  useEffect(() => {
    setHeader({ pageName: "Profil", progress: 40 })
    setTitle({ text: "Complétez votre profil", highlight: "votre profil" })
    setShowBackButton(true)
    setCta({
      onClick: handleClick,
      isDisabled,
      text: "Continuer",
    })
  }, [setHeader, setTitle, setShowBackButton, setCta, isDisabled, handleClick])

  useEffect(() => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [])

  return (
    <Container maxW="container.xl" maxWidth="600px" mx="auto">
      <VStack spacing={8} align="stretch">
        {questions.map((question) => {
          // @ts-expect-error profile is not expected to be defined on profileQuestionnaire
          if (!question.shouldShow(form.values.profile, undefined)) {
            return null
          }
          return (
            <Question
              key={question.id}
              question={question}
              questionnaireName="profile"
              // @ts-expect-error TODO FIXME: Cannot manage to make it compile
              form={form}
            />
          )
        })}

        {!user && (
          <VStack spacing={4} align="stretch" mt={4} maxWidth="600px" mx="auto">
            <Checkbox
              isChecked={form.values.cgu}
              onChange={(e) => form.setFieldValue("cgu", e.target.checked)}
            >
              <Text fontSize="sm">
                En continuant, vous acceptez que vos données personnelles soient
                utilisées pour votre prise en charge médicale, conformément à
                notre{" "}
                <ChakraLink
                  as={Link}
                  to="https://www.bleu.care/legal/rgpd"
                  target="_blank"
                  color="blue.500"
                >
                  Politique de confidentialité des données
                </ChakraLink>{" "}
                et nos{" "}
                <ChakraLink
                  as={Link}
                  to="https://www.bleu.care/legal/cgu"
                  target="_blank"
                  color="blue.500"
                >
                  Conditions générales d&apos;utilisation
                </ChakraLink>
                .*
              </Text>
            </Checkbox>
            <Checkbox
              isChecked={form.values.acceptsMarketing}
              onChange={(e) =>
                form.setFieldValue("acceptsMarketing", e.target.checked)
              }
            >
              <Text fontSize="sm">
                J&apos;accepte de recevoir d&apos;autres communications de Bleu
                (rappels de dépistages, recommandations, etc.)
              </Text>
            </Checkbox>
          </VStack>
        )}
        <Text
          color="navy.300"
          fontWeight={400}
          mt={0}
          mb={6}
          maxWidth="600px"
          mx="auto"
        >
          Bleu n&apos;est pas adapté à la prise en charge de situations
          d&apos;urgence vitale (en cas d&apos;urgence, appelez le 15), ni au
          suivi des pathologies chroniques.
        </Text>
      </VStack>
    </Container>
  )
}
