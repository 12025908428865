import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react"

import { User } from "@bleu/types/user"

import { logger } from "@bleu/front/utils/logger"

interface UserContextType {
  user: User
  updateUser: (user: User) => void
}

export const UserContext = createContext<UserContextType>({
  user: {} as User,
  updateUser: () => {},
})

interface UserProviderProps {
  user: User
  children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({
  user,
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User>(user)

  const updateUser = useCallback((newUser: User) => {
    setCurrentUser(newUser)
  }, [])

  const contextValue = useMemo(
    () => ({ user: currentUser, updateUser }),
    [currentUser, updateUser],
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    logger.error("useUser hook called outside of UserProvider")
    throw new Error("useUser must be used within a UserProvider")
  }
  return context.user
}
