import { create } from "zustand"
import { persist } from "zustand/middleware"

interface DeviceStore {
  deviceId: string
  firstVisit: string | null
  lastVisit: string | null
  setDeviceId: (id: string) => void
  setFirstVisit: (time: string) => void
  setLastVisit: (time: string) => void
}

export const useDeviceStore = create<DeviceStore>()(
  persist(
    (set) => ({
      deviceId: "",
      firstVisit: null,
      lastVisit: null,
      setDeviceId: (id: string) => set({ deviceId: id }),
      setFirstVisit: (time: string) => set({ firstVisit: time }),
      setLastVisit: (time: string) => set({ lastVisit: time }),
    }),
    {
      name: "device-storage",
    },
  ),
)
