import { Container, Stack, Text } from "@chakra-ui/react"
import { RedirectToSignUp } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import { useMutation } from "@tanstack/react-query"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { MedicalRecord } from "@bleu/types/prisma-client"

import { VerticalName } from "@bleu/shared/verticals"

import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { Loader } from "@bleu/front/components/layout/Loader"
import { apiClient } from "@bleu/front/lib/apiClient"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { useAuthState } from "@bleu/front/queries/auth"
import { logger } from "@bleu/front/utils/logger"
import { useAmplitudeTrack } from "@bleu/front/utils/tracking"

import { Steps } from "../hooks"

type Props = {
  medicalRecordCode: string
  onSaveAnswers: (medicalRecordCode: string) => Promise<MedicalRecord>
  verticalSelection: VerticalName[]
}

export const Payment = ({
  medicalRecordCode,
  onSaveAnswers,
  verticalSelection: selectedVerticals,
}: Props) => {
  const { isLoading, isSignedIn } = useAuthState()
  const hasInitiatedCheckout = useRef(false)
  const { setHeader, setTitle, setCta, setShowBackButton } =
    useQuestionnaireLayoutStore()
  const navigate = useNavigate()
  const { track } = useAmplitudeTrack()

  logger.info("Payment component rendered", { medicalRecordCode })
  logger.info("Selected verticals", { selectedVerticals })

  const { mutateAsync: createCheckoutSession, isPending } = useMutation({
    mutationFn: async () => {
      logger.info("Creating checkout session", {
        medicalRecordCode,
        selectedVerticals,
      })
      const verticalToUse =
        selectedVerticals.length > 1
          ? VerticalName.GENERAL
          : selectedVerticals[0]

      const response = await apiClient.post<{ url: string }>(
        "/stripe/create-checkout-session",
        { medicalRecordCode, vertical: verticalToUse },
      )
      track("Funnel - Start Payment", {})
      logger.info("Checkout session created", { medicalRecordCode })
      return response.data
    },
    onSuccess: (data) => {
      logger.info("Redirecting to checkout", {
        medicalRecordCode,
        url: data.url,
      })
      window.location.href = data.url
    },
    onError: (error) => {
      logger.error("Error creating checkout session", {
        medicalRecordCode,
        error,
      })
      Sentry.captureException(error, { extra: { medicalRecordCode } })
      const newSearchParams = new URLSearchParams(window.location.search)
      newSearchParams.set("step", Steps.recap.toString())
      navigate(`/questionnaire?${newSearchParams.toString()}`)
    },
  })

  useEffect(() => {
    const initiateCheckout = async () => {
      if (
        !isLoading &&
        isSignedIn &&
        !isPending &&
        !hasInitiatedCheckout.current
      ) {
        logger.info("Initiating checkout process", { medicalRecordCode })
        hasInitiatedCheckout.current = true
        try {
          logger.info("Saving answers", { medicalRecordCode })
          await onSaveAnswers(medicalRecordCode)
          logger.info("Answers saved successfully", { medicalRecordCode })
          await createCheckoutSession()
        } catch (e) {
          logger.error("Error during checkout process", {
            medicalRecordCode,
            error: e,
          })
          Sentry.captureException(e, { extra: { medicalRecordCode } })
          const newSearchParams = new URLSearchParams(window.location.search)
          newSearchParams.set("step", Steps.recap.toString())
          navigate(`/questionnaire?${newSearchParams.toString()}`)
        }
      }
    }

    initiateCheckout()
  }, [
    isLoading,
    isSignedIn,
    isPending,
    medicalRecordCode,
    createCheckoutSession,
    onSaveAnswers,
    navigate,
  ])

  useEffect(() => {
    setHeader({ pageName: "Paiement", progress: 100 })
    setTitle("Finalisez votre commande")
    setShowBackButton(true)
    setCta({
      onClick: () => {},
      isDisabled: true,
      text: "Paiement en cours...",
    })
  }, [setHeader, setTitle, setShowBackButton, setCta])

  if (isLoading) {
    logger.info("Rendering FullPageLoader", { medicalRecordCode })
    return <FullPageLoader />
  }
  if (!isSignedIn) {
    logger.info("User not signed in, redirecting to sign up", {
      medicalRecordCode,
    })
    return (
      <RedirectToSignUp
        redirectUrl={`/questionnaire?step=${Steps[Steps.payment]}`}
      />
    )
  }

  logger.info("Rendering Payment component content", { medicalRecordCode })
  return (
    <Container maxW="container.xl">
      <Stack gap={6}>
        <Text size="xl" fontWeight={600} my={2} display={["none", "block"]}>
          Dernière étape
        </Text>
        <Text display={["none", "block"]} size="3xl">
          Redirection vers la page de paiement
        </Text>
        {isPending ? (
          <Loader />
        ) : (
          <Text>Préparation de votre session de paiement...</Text>
        )}
      </Stack>
    </Container>
  )
}
