import _ from "lodash"
import { useMemo, useCallback } from "react"

import {
  getVerticalNameForDepistageName,
  depistages,
} from "@bleu/shared/depistages"
import { Depistage } from "@bleu/shared/depistages/types"
import {
  getDepistagesForVertical,
  isVerticalName,
} from "@bleu/shared/verticals"

import {
  useProfileStore,
  useFunnelStore,
  useVerticalsStore,
  useDepistagesStore,
} from "@bleu/front/lib/stores"
import { logger } from "@bleu/front/utils/logger"

/**
 * Custom hook to manage depistages selection based on user profile and selected verticals.
 *
 * @returns An object containing relevant depistages, selected depistages, questionnaires to take, and prescribed depistages.
 */
export const useDepistages = () => {
  const { profile } = useProfileStore()
  const { prescribedDepistages } = useFunnelStore()
  const { selectedVerticals } = useVerticalsStore()
  const { excludedDepistages } = useDepistagesStore()

  /**
   * Memoized function to compute all depistages based on selected verticals.
   */
  const getAllDepistages = useCallback((verticals: string[]): Depistage[] => {
    return verticals.flatMap((vertical) => {
      if (isVerticalName(vertical)) {
        return getDepistagesForVertical(vertical)
      } else {
        return [depistages[vertical as keyof typeof depistages]]
      }
    })
  }, [])

  /**
   * Computes the relevant depistages based on the user's profile and selected verticals.
   */
  const relevantDepistages = useMemo(() => {
    if (!profile) {
      logger.info("No profile available, returning empty depistages array.")
      return []
    }

    logger.info(
      `Starting depistage selection. Selected verticals: ${JSON.stringify(selectedVerticals)}`,
    )

    const allDepistages = getAllDepistages(selectedVerticals)

    logger.info(
      `All depistages before filtering: ${JSON.stringify(allDepistages.map((d) => d.name))}`,
    )

    const relevantDepistages = allDepistages
      .filter((depistage) => {
        const isRelevant = depistage.isRelevantToProfile(profile)
        logger.info(
          `Depistage ${depistage.name} relevant to profile: ${isRelevant}. Profile: ${JSON.stringify(profile)}`,
        )
        return isRelevant
      })
      .map((depistage) => ({
        ...depistage,
        vertical: getVerticalNameForDepistageName(depistage.name),
      }))

    return _.uniqBy(
      _.orderBy(relevantDepistages, (depistage) => depistage.vertical),
      "name",
    )
  }, [profile, selectedVerticals, getAllDepistages])

  /**
   * Filters depistages by excluding any that are in the excludedDepistages list.
   */
  const selectedDepistages = useMemo(() => {
    return relevantDepistages.filter(
      (depistage) => !excludedDepistages.includes(depistage.name),
    )
  }, [relevantDepistages, excludedDepistages])

  /**
   * Extracts questionnaires to take from the selected depistages.
   */
  const questionnairesToTake = useMemo(() => {
    const questionnaires = selectedDepistages
      .filter((depistage) => depistage.questionnaire !== undefined)
      .map((depistage) => {
        if (!depistage.questionnaire) {
          logger.warn(
            `Questionnaire undefined for depistage: ${depistage.name}`,
          )
        }
        return depistage.questionnaire!
      })

    logger.info(
      `Questionnaires to take: ${JSON.stringify(questionnaires.map((q) => q.name))}`,
    )

    return questionnaires
  }, [selectedDepistages])

  return useMemo(
    () => ({
      relevantDepistages,
      selectedDepistages,
      questionnairesToTake,
      prescribedDepistages,
    }),
    [
      relevantDepistages,
      selectedDepistages,
      questionnairesToTake,
      prescribedDepistages,
    ],
  )
}
