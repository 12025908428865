import * as Sentry from "@sentry/react"
import _ from "lodash"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { createRandomMedicalRecordCode } from "@bleu/shared/utils/random"

import { useFunnelStore, useProfileStore } from "@bleu/front/lib/stores"
import { useCreateMedicalRecordMutation } from "@bleu/front/queries/medicalRecords"
import { logger } from "@bleu/front/utils/logger"

export const useAnswersSaving = () => {
  const navigate = useNavigate()
  const { mutateAsync: saveAnswers } = useCreateMedicalRecordMutation()
  const { answers: allQuestionnairesAnswers, prescribedDepistages } =
    useFunnelStore()
  const { profile, acceptsMarketing } = useProfileStore()

  const medicalRecordCode = useMemo(createRandomMedicalRecordCode, [])

  const handleSaveAnswers = async (medicalRecordCode: string) => {
    logger.info("Attempting to save answers...")
    if (_.isEmpty(allQuestionnairesAnswers)) {
      logger.info("No answers found, redirecting to questionnaire page...")
      navigate("/questionnaire")
      throw new Error("Trying to save without answers")
    }
    try {
      logger.info("Saving medical record with provided answers...")

      const medicalRecord = await saveAnswers({
        medicalRecordCode,
        answers: allQuestionnairesAnswers,
        profile: profile!,
        prescribedDepistages,
        acceptsMarketing,
      })

      logger.info("Answers saved successfully, resetting funnel store...")
      // resetFunnelStore()
      return medicalRecord
    } catch (e) {
      logger.error("Error saving answers")
      if (e instanceof Error) {
        Sentry.captureException(e, { extra: { medicalRecordCode } })
      } else {
        Sentry.captureException(new Error("Unknown error saving answers"), {
          extra: { medicalRecordCode, originalError: e },
        })
      }
      throw e
    }
  }

  return { handleSaveAnswers, medicalRecordCode }
}
