import { create } from "zustand"

import { HighlightText } from "@bleu/front/components/layout/components/LayoutTitle"

interface QuestionnaireLayoutState {
  header: { pageName?: string; progress?: number }
  title: string | HighlightText
  cta: { onClick: () => void; isDisabled?: boolean; text: string } | null
  onBack: { onClick: () => void; isDisabled?: boolean; text: string } | null
  currentStep: number
  totalSteps: number
  stepNames: string[]
  showBackButton: boolean
  setHeader: (header: { pageName?: string; progress?: number }) => void
  setTitle: (title: string | HighlightText) => void
  setCta: (cta: {
    onClick: () => void
    isDisabled?: boolean
    text: string
  }) => void
  setOnBack: (onBack: {
    onClick: () => void
    isDisabled?: boolean
    text: string
  }) => void
  setShowBackButton: (show: boolean) => void
  setCurrentStep: (step: number) => void
  setTotalSteps: (steps: number) => void
  setStepNames: (names: string[]) => void
}

export const useQuestionnaireLayoutStore = create<QuestionnaireLayoutState>(
  (set) => ({
    header: {},
    title: "",
    cta: null,
    onBack: null,
    currentStep: 0,
    totalSteps: 0,
    stepNames: [],
    showBackButton: false,
    setHeader: (header) => set({ header }),
    setTitle: (title) => set({ title }),
    setCta: (cta) => set({ cta }),
    setOnBack: (onBack) => set({ onBack }),
    setShowBackButton: (show) => set({ showBackButton: show }),
    setCurrentStep: (step) => set({ currentStep: step }),
    setTotalSteps: (steps) => set({ totalSteps: steps }),
    setStepNames: (names) => set({ stepNames: names }),
  }),
)
