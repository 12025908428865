import { Container } from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { useCallback, useEffect, useMemo } from "react"
import { z, type ZodType } from "zod"

import type { Record } from "@bleu/types/prisma-client/runtime/library"

import {
  type AllQuestionnairesAnswers,
  type ProfileData,
  type QuestionnaireName,
  type QuestionnaireShape,
  QuestionTypes,
} from "@bleu/shared/questionnaires"

import { Questionnaire } from "@bleu/front/components/funnelSteps/Questionnaires/Questionnaire"
import { useQuestionnaireStore } from "@bleu/front/lib/stores"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"

import { useStepNavigation } from "../hooks/useStepNavigation"

const getFormInitialValuesForQuestionnaires = (
  questionnaires: QuestionnaireShape[],
  storedAnswers: AllQuestionnairesAnswers,
): AllQuestionnairesAnswers => {
  return questionnaires.reduce(
    (acc, questionnaire): AllQuestionnairesAnswers => ({
      ...acc,
      [questionnaire.name]:
        storedAnswers[questionnaire.name] || questionnaire.initialValues,
    }),
    {} as AllQuestionnairesAnswers,
  )
}

const getFormSchemaForQuestionnaires = (
  questionnaires: QuestionnaireShape[],
) => {
  const allSchemas = questionnaires.reduce(
    (acc, questionnaire): Partial<Record<QuestionnaireName, ZodType>> => {
      const newSchema = questionnaire.schema
      return { ...acc, [questionnaire.name]: newSchema }
    },
    {},
  )
  return z.object(allSchemas)
}

const getNumberFieldsForQuestionnaires = (
  questionnaires: QuestionnaireShape[],
) => {
  return questionnaires.flatMap((questionnaire) => {
    const numberQuestions = questionnaire.questions.filter(
      (question) => question.type === QuestionTypes.Number,
    )
    return numberQuestions.map(
      (question) => `${questionnaire.name}.${question.id}`,
    )
  })
}

interface Props {
  questionnaires: QuestionnaireShape[]
  profile: ProfileData
  onSubmit: (data: AllQuestionnairesAnswers) => void
}

export const Questionnaires = ({
  questionnaires,
  onSubmit,
  profile,
}: Props) => {
  const answers = useQuestionnaireStore((state) => state.answers)
  const setAnswers = useQuestionnaireStore((state) => state.setAnswers)
  const { setHeader, setTitle, setCta, setShowBackButton } =
    useQuestionnaireLayoutStore()
  const { handleNext, goToPreviousStep, subStepParam } = useStepNavigation(
    new URLSearchParams(window.location.search),
  )

  const formSchema = useMemo(
    () => getFormSchemaForQuestionnaires(questionnaires),
    [questionnaires],
  )
  const numberFields = useMemo(
    () => getNumberFieldsForQuestionnaires(questionnaires),
    [questionnaires],
  )
  const initialValues = useMemo(
    () => getFormInitialValuesForQuestionnaires(questionnaires, answers),
    [questionnaires, answers],
  )

  const form = useForm<AllQuestionnairesAnswers>({
    initialValues,
    validate: zodResolver(formSchema),
    validateInputOnChange: numberFields,
    onValuesChange: (values) => {
      setAnswers(values)
    },
  })

  const isDisabled = useMemo(() => {
    if (!questionnaires.length) return true
    return !form.isValid(questionnaires[subStepParam]?.name)
  }, [subStepParam, form, questionnaires])

  const handleSubmit = useCallback(() => {
    if (subStepParam === questionnaires.length - 1) {
      const parsedAnswers = questionnaires.reduce(
        (acc, questionnaire): AllQuestionnairesAnswers => ({
          ...acc,
          [questionnaire.name]: questionnaire.schema.parse(
            form.values[questionnaire.name],
          ),
        }),
        {},
      )
      onSubmit(parsedAnswers)
    }
    handleNext(questionnaires.length)
  }, [subStepParam, questionnaires, handleNext, onSubmit, form.values])

  useEffect(() => {
    if (questionnaires.length === 0) {
      handleNext()
    }
  }, [questionnaires, handleNext])

  useEffect(() => {
    const currentQuestionnaire = questionnaires[subStepParam]
    if (!currentQuestionnaire) return

    const progress = ((subStepParam + 1) / questionnaires.length) * 20 + 60

    setHeader({
      pageName: currentQuestionnaire.name,
      progress,
    })
    setTitle({
      text: "Questions relatives à votre dépistage",
      highlight: "Questions relatives",
    })
    setShowBackButton(true)
    setCta({
      onClick: handleSubmit,
      isDisabled,
      text:
        subStepParam === questionnaires.length - 1 ? "Terminer" : "Continuer",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled])

  if (!questionnaires.length) return null

  const currentQuestionnaire = questionnaires[subStepParam]

  return (
    <Container maxW="container.xl" maxWidth="600px" mx="auto">
      <Questionnaire
        questionnaire={currentQuestionnaire}
        profile={profile}
        form={form}
        onEmptyQuestionnaire={() => handleNext(questionnaires.length)}
        onNext={() => handleNext(questionnaires.length)}
        onPrevious={goToPreviousStep}
      />
    </Container>
  )
}
