import { useEffect } from "react"

import { useDepistagesStore } from "@bleu/front/lib/stores/depistagesStore"
import { useFunnelStore } from "@bleu/front/lib/stores/funnelStore"
import { useLayoutStore } from "@bleu/front/lib/stores/layoutStore"
import { useQuestionnaireStore } from "@bleu/front/lib/stores/questionnaireStore"
import { useVerticalsStore } from "@bleu/front/lib/stores/verticalStore"

/**
 * A custom hook that resets multiple stores when the component mounts.
 *
 * This hook performs the following actions:
 * - Resets the depistages store
 * - Resets the funnel store
 * - Clears the title, page name, and progress in the layout store
 * - Resets the questionnaire store
 * - Resets the verticals store
 *
 * @returns {void}
 */
export const useResetStores = (): void => {
  useEffect(() => {
    useDepistagesStore.getState().reset()
    useFunnelStore.getState().reset()
    useLayoutStore.getState().setTitle("")
    useLayoutStore.getState().setPageName(undefined)
    useLayoutStore.getState().setProgress(undefined)
    useQuestionnaireStore.getState().reset()
    useVerticalsStore.getState().reset()
  }, [])
}
