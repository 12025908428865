import { create } from "zustand"
import { persist } from "zustand/middleware"

import { AllQuestionnairesAnswers } from "@bleu/shared/questionnaires"

interface QuestionnaireState {
  answers: AllQuestionnairesAnswers
  setAnswers: (answers: AllQuestionnairesAnswers) => void
  reset: () => void
}

export const useQuestionnaireStore = create<QuestionnaireState>()(
  persist(
    (set) => ({
      answers: {},
      setAnswers: (answers) => set({ answers }),
      reset: () => set({ answers: {} }),
    }),
    {
      name: "questionnaire-storage",
    },
  ),
)
