import {
  STIReasonOption,
  STISymptomsOption,
  YesNoOther,
} from "@bleu/shared/questionnaires"
import {
  BiologicalSexOption,
  STICurrentSituationOption,
  STIDetectionMethodsOption,
} from "@bleu/shared/questionnaires/enums"

import type { ComputePrescription } from "./types"

export const STI_PRESCRIPTIONS = {
  GCUrine: {
    shortName: "GC Urine",
    title: "Faire réaliser un prélèvement d'urine pour",
    description:
      "PCR chlamydia et gonocoque\n\n" +
      "(premier jet, au plus tôt 2 heures après la précédente miction)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GCUrineMyco: {
    shortName: "GC Urine + MG",
    title: "Faire réaliser un prélèvement d'urine pour",
    description:
      "PCR chlamydia et gonocoque\n" +
      "PCR Mycoplasma Genitalium\n" +
      "Recherche par TAAN de mutations de résistance aux macrolides)\n\n" +
      "(premier jet, au plus tôt 2 heures après la précédente miction)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GCUrethral: {
    shortName: "GC Urétral",
    title: "Faire réaliser un prélèvement urétral pour",
    description:
      "Examen cyto-myco-parasito-bactériologique (le matin avant toute toilette ou au moins 2 heures après la dernière miction).\n" +
      "PCR chlamydia et gonocoques PCR Mycoplasma Genitalium",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GCVaginal: {
    shortName: "GC Vaginal",
    title: "Faire réaliser un prélèvement vaginal pour",
    description:
      "PCR chlamydia et gonocoques\n" +
      "(auto prélèvement possible)\n" +
      "sans examen cytobactériologique",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GCVaginalNugent: {
    shortName: "GC Vaginal + MG + Nugent",
    title: "Faire réaliser un prélèvement vaginal pour",
    description:
      "PCR chlamydia et gonocoques\n" +
      "PCR Mycoplasma Genitalium\n" +
      "Recherche par TAAN de mutations de résistance aux macrolides.\n" +
      "Examen cyto-myco-parasito-bactériologique (+ score Nugent))\n" +
      "(auto prélèvement possible)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GCRectal: {
    shortName: "GC Rectal",
    title: "Faire réaliser un prélèvement anal pour",
    description: "PCR chlamydia et gonocoques",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GCThroat: {
    shortName: "GC Throat",
    title: "Faire réaliser un prélèvement pharyngé pour",
    description: "PCR chlamydia et gonocoques",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GC3SMale: {
    shortName: "GC3S Male",
    title: "Faire réaliser un prélèvement 3 sites",
    description:
      "(urines, anal, pharyngé)\n" +
      "PCR chlamydia et gonocoques\n" +
      "(prélèvement d’urines : premier jet, au plus tôt 2 heures après une précédente miction)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GC3SMaleMyco: {
    shortName: "GC3S Male + MG",
    title: "Faire réaliser un prélèvement 3 sites",
    description:
      "(urines, anal, pharyngé)\n" +
      "PCR chlamydia et gonocoques\n" +
      "Sur prélèvement urinaire uniquement: PCR Mycoplasma Genitalium + recherche par TAAN de mutations de résistance aux macrolides\n\n" +
      "(prélèvement d’urines : premier jet, au plus tôt 2 heures après une précédente miction)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GC3SFemale: {
    shortName: "GC3S Female",
    title: "Faire réaliser un prélèvement 3 sites",
    description:
      "(vaginal, anal, pharyngé)\n" +
      "PCR chlamydia et gonocoques\n" +
      "(prélèvement vaginal : auto prélèvement possible)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  GC3SFemaleNugent: {
    shortName: "GC3S Female + MG + Nugent",
    title: "Faire réaliser un prélèvement 3 sites",
    description:
      "(vaginal, anal, pharyngé)\n" +
      "PCR chlamydia et gonocoques\n" +
      "Sur prélèvement vaginal uniquement: PCR Mycoplasma Genitalium + recherche par TAAN de mutations de résistance aux macrolides, examen cyto-myco-parasito-bactériologique (+ score Nugent)\n\n" +
      "(prélèvement vaginal : auto prélèvement possible)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  vih: {
    shortName: "VIH",
    title: "Faire réaliser une sérologie VIH",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  vha: {
    shortName: "VHA",
    title: "Faire réaliser une sérologie VHA",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  vhb: {
    shortName: "VHB",
    title: "Faire réaliser une sérologie VHB",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  vhc: {
    shortName: "VHC",
    title: "Faire réaliser une sérologie VHC",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  syphilis: {
    shortName: "Syphilis",
    title: "Faire réaliser une sérologie de la syphilis",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  tricho: {
    shortName: "Tricho",
    title: "PCR Trichomonas Vaginalis",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  econazole: {
    shortName: "Econazole",
    title: "ECONAZOLE 150 mg, ovules, 1 boîte de trois",
    description: "Un ovule intravaginal le soir pendant 3 jours",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  monazol: {
    shortName: "MONAZOL",
    title: "MONAZOL Crème, 1 tube",
    description: "1 application 2 fois par jour sur la vulve pendant 1 semaine",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  metronidazole: {
    shortName: "METRONIDAZOLE",
    title: "METRONIDAZOLE 500 mg, comprimés",
    description:
      "4 comprimés en une prise unique à la fin du repas du soir. \n" +
      "Pas d’alcool pendant 48h\n" +
      "QSP",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  physioflor: {
    shortName: "PHYSIOFLOR",
    title: "PHYSIOFLOR, gélules vaginales, boîte de 7 gélules",
    description:
      "1 gélule intra vaginale par jour pendant 7 jours\n" + "1 boîte",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  ctazu: {
    shortName: "CTAzU",
    title: "Azithromycine 250 mg, cp pelliculés",
    description: "4 comprimés en une prise unique\n" + "QSP 1 gramme",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  ctdoxyu: {
    shortName: "CTDoxyU",
    title: "Doxycycline 100mg, cp",
    description:
      "1 comprimé matin et soir pendant 7 jours\n" +
      "à prendre pendant les repas avec suffisamment d’eau\n" +
      "Eviter l’exposition au soleil\n" +
      "QSP 7 jours",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  ctdoxyrsimple: {
    shortName: "CTDoxyRSimple",
    title: "Doxycycline 100mg, cp",
    description:
      "1 comprimé matin et soir pendant 7 jours\n" +
      "à prendre pendant les repas avec suffisamment d’eau\n" +
      "Eviter l’exposition au soleil\n" +
      "QSP 7 jours",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  ctdoxyrcompl: {
    shortName: "CTDoxyRCompl",
    title: "Doxycycline 100mg, cp",
    description:
      "1 comprimé matin et soir pendant 21 jours\n" +
      "à prendre pendant les repas avec suffisamment d’eau\n" +
      "Eviter l’exposition au soleil\n" +
      "QSP 21 jours",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  doxymg: {
    shortName: "doxyMG",
    title: "Doxycycline 100 mg, comprimés",
    description:
      "1 comprimé 2 fois par jour pendant 7 jours (J1 à J7)\n" +
      "à prendre pendant les repas avec suffisamment d’eau\n" +
      "éviter l’exposition au soleil\n" +
      "QSP 7 jours",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  azythrmg: {
    shortName: "azythrMG",
    title: "Azithromycine 250 mg, cp pelliculés",
    description:
      "2 comprimés à J1\n" +
      "puis 1 comprimé par jour à J2, J3, J4 et J5\n" +
      "QSP 1,5 grammes",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  mgres: {
    shortName: "MGrés",
    title: "Moxifloxacine 400 mg, comprimés",
    description:
      "1 comprimé 1 fois par jour pendant 7 jours\n" +
      "à prendre en dehors des repas avec un grand vers d’eau\n" +
      "QSP 7 jours",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
} as const

export const stiComputePrescription: ComputePrescription = (
  { sti },
  { biologicalSex },
) => {
  if (!sti) {
    return []
  }

  const {
    detectionMethods = [],
    reason,
    symptoms = [],
    declineHIV,
    hepatiteVaccine,
    currentSituation = [],
  } = sti

  const prescriptions: (typeof STI_PRESCRIPTIONS)[keyof typeof STI_PRESCRIPTIONS][] =
    []

  if (!declineHIV) {
    prescriptions.push(STI_PRESCRIPTIONS.vih)
  }

  if ([YesNoOther.NO, YesNoOther.OTHER].includes(hepatiteVaccine)) {
    prescriptions.push(STI_PRESCRIPTIONS.vhb)
  }

  const shouldTakeCytoMycoParasitoBacterioExam =
    biologicalSex === BiologicalSexOption.FEMALE &&
    reason === STIReasonOption.SYMPTOMS &&
    symptoms.includes(STISymptomsOption.DISCHARGE)

  const isThreeSites =
    (detectionMethods.includes(STIDetectionMethodsOption.URINE_SAMPLE) ||
      detectionMethods.includes(STIDetectionMethodsOption.VAGINAL_SWAB)) &&
    detectionMethods.includes(STIDetectionMethodsOption.RECTAL_SWAB) &&
    detectionMethods.includes(STIDetectionMethodsOption.THROAT_SWAB)

  if (isThreeSites) {
    if (biologicalSex === BiologicalSexOption.MALE) {
      if (
        reason === STIReasonOption.SYMPTOMS &&
        symptoms.includes(STISymptomsOption.URINARY_BURNING_OR_LEAKS)
      ) {
        prescriptions.push(STI_PRESCRIPTIONS.GC3SMaleMyco)
      } else {
        prescriptions.push(STI_PRESCRIPTIONS.GC3SMale)
      }
    } else {
      if (shouldTakeCytoMycoParasitoBacterioExam) {
        prescriptions.push(STI_PRESCRIPTIONS.GC3SFemaleNugent)
      } else {
        prescriptions.push(STI_PRESCRIPTIONS.GC3SFemale)
      }
    }
  } else {
    if (detectionMethods.includes(STIDetectionMethodsOption.URINE_SAMPLE)) {
      if (
        reason === STIReasonOption.SYMPTOMS &&
        symptoms.includes(STISymptomsOption.URINARY_BURNING_OR_LEAKS)
      ) {
        prescriptions.push(STI_PRESCRIPTIONS.GCUrineMyco)
      } else {
        prescriptions.push(STI_PRESCRIPTIONS.GCUrine)
      }
    }
    if (detectionMethods.includes(STIDetectionMethodsOption.VAGINAL_SWAB)) {
      if (shouldTakeCytoMycoParasitoBacterioExam) {
        prescriptions.push(STI_PRESCRIPTIONS.GCVaginalNugent)
      } else {
        prescriptions.push(STI_PRESCRIPTIONS.GCVaginal)
      }
    }
    if (detectionMethods.includes(STIDetectionMethodsOption.RECTAL_SWAB)) {
      prescriptions.push(STI_PRESCRIPTIONS.GCRectal)
    }
    if (detectionMethods.includes(STIDetectionMethodsOption.THROAT_SWAB)) {
      prescriptions.push(STI_PRESCRIPTIONS.GCThroat)
    }
  }

  const highRiskSituations = [
    STICurrentSituationOption.RECENT_STI,
    STICurrentSituationOption.MULTIPLE_PARTNERS,
    STICurrentSituationOption.MAN_TO_MAN_RELATIONS,
    STICurrentSituationOption.SEX_WORKER,
  ]

  if (
    highRiskSituations.some((situation) => currentSituation.includes(situation))
  ) {
    prescriptions.push(STI_PRESCRIPTIONS.syphilis)
  }

  if (
    currentSituation.includes(STICurrentSituationOption.MAN_TO_MAN_RELATIONS) ||
    currentSituation.includes(STICurrentSituationOption.SEX_WORKER)
  ) {
    prescriptions.push(STI_PRESCRIPTIONS.vha)
  }

  const additionalVHCConditions = [
    STICurrentSituationOption.MAN_TO_MAN_RELATIONS,
    STICurrentSituationOption.SEX_WORKER,
    STICurrentSituationOption.DRUGS,
    STICurrentSituationOption.HEPATITE_C_RELATIVE,
  ]

  if (
    additionalVHCConditions.some((situation) =>
      currentSituation.includes(situation),
    )
  ) {
    prescriptions.push(STI_PRESCRIPTIONS.vhc)
  }

  return prescriptions
}
