import {
  track as amplitudeTrack,
  identify as amplitudeIdentify,
  setDeviceId as amplitudeSetDeviceId,
} from "@amplitude/analytics-browser"
import { Identify } from "@amplitude/analytics-core"
import { usePostHog } from "posthog-js/react"
import { useCallback, useMemo } from "react"

import { useDeviceStore } from "../lib/stores/deviceStore"

type GTMEvent =
  | "recap_prescription"
  | "start_payment"
  | "payment_success"
  | "payment_failed"

/**
 * Tracks a page view event using Amplitude SDK
 * @param url - The URL of the page viewed
 */
export const pageview = (url: string) => {
  amplitudeTrack("Page View", { page: url })
}

// Define the default Amplitude endpoint
const DEFAULT_AMPLITUDE_ENDPOINT = "https://api.eu.amplitude.com"

/**
 * Function to determine the appropriate Amplitude endpoint
 */
export function getAmplitudeEndpoint() {
  return DEFAULT_AMPLITUDE_ENDPOINT
}

/**
 * Tracks a custom event using Amplitude SDK and Google Analytics
 * @param event - The name of the event
 * @param eventProperties - Additional properties for the event
 */
export const gtmTrack = (
  event: GTMEvent,
  eventProperties?: Record<string, unknown>,
) => {
  // Track in Amplitude (keeping existing functionality)
  amplitudeTrack(event, eventProperties)

  // Track in GTM/GA4
  if (typeof window.dataLayer !== "undefined") {
    // Push standard GTM event
    window.dataLayer.push({
      event,
      ...eventProperties,
      send_to: "G-8L5Z3S8BTZ",
      event_category: "ecommerce",
      non_interaction: false,
    })

    // Push GA4-specific event
    window.dataLayer.push({
      event: `ga4_${event}`,
      ...eventProperties,
      send_to: "G-8L5Z3S8BTZ",
      event_category: "ecommerce",
      non_interaction: false,
    })

    // Additional ecommerce data for payment success
    if (event === "payment_success") {
      // Standard ecommerce event
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: eventProperties?.sessionId,
          value: eventProperties?.price,
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
      })

      // GA4-specific purchase event
      window.dataLayer.push({
        event: "ga4_purchase",
        ecommerce: {
          transaction_id: eventProperties?.sessionId,
          value: eventProperties?.price,
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
        send_to: "G-8L5Z3S8BTZ",
      })
    }
  } else if (import.meta.env.DEV) {
    console.log("GTM event:", { event, eventProperties })
  }
}

/**
 * Sets the user ID in Amplitude
 * @param userId - The unique identifier for the user
 */
export const setAmplitudeUserId = (userId: string) => {
  amplitudeIdentify(new Identify().set("user_id", userId))
  if (import.meta.env.DEV) {
    console.log("Amplitude user ID set to", userId)
  }
}

/**
 * Custom hook that provides tracking functionality with PostHog and Amplitude integration
 * @returns Memoized tracking functions to prevent unnecessary re-renders
 */
export function useAmplitudeTrack() {
  const posthog = usePostHog()

  const track = useCallback(
    (eventName: string, eventProperties: Record<string, unknown>) => {
      const posthogDistinctId = posthog?.get_distinct_id?.()

      // Ensure device ID is set
      if (!useDeviceStore.getState().deviceId) {
        const newDeviceId = crypto.randomUUID()
        useDeviceStore.getState().setDeviceId(newDeviceId)
      }

      const trackingProperties = {
        ...eventProperties,
        posthog_distinct_id: posthogDistinctId,
        session_id: crypto.randomUUID(),
        is_first_visit: !useDeviceStore.getState().firstVisit,
        last_visit_time: useDeviceStore.getState().lastVisit,
        user_id: posthogDistinctId,
        device_id: useDeviceStore.getState().deviceId,
      }

      // Track event in Amplitude
      amplitudeTrack(eventName, trackingProperties)

      // Track the same event in PostHog
      posthog.capture(eventName, {
        ...eventProperties,
        posthog_distinct_id: posthogDistinctId,
        session_id: trackingProperties.session_id,
        is_first_visit: trackingProperties.is_first_visit,
        last_visit_time: trackingProperties.last_visit_time,
        user_id: trackingProperties.user_id,
        device_id: trackingProperties.device_id,
      })

      // Update visit timestamps
      if (!useDeviceStore.getState().firstVisit) {
        useDeviceStore.getState().setFirstVisit(Date.now().toString())
      }
      useDeviceStore.getState().setLastVisit(Date.now().toString())

      if (import.meta.env.DEV) {
        console.log("Tracked event", eventName, eventProperties)
      }
    },
    [posthog],
  )

  const identify = useCallback(
    (userId: string) => {
      const identifyInstance = new Identify().set("user_id", userId)
      amplitudeIdentify(identifyInstance)
      if (import.meta.env.DEV) {
        console.log("Amplitude identify", userId)
      }
    },
    [], // No dependencies as it doesn't use any external values
  )

  // Memoize the returned object to maintain referential equality
  return useMemo(
    () => ({
      track,
      identify,
    }),
    [track, identify],
  )
}

/**
 * Ensures a device ID is set in the store and Amplitude.
 */
export function initializeDevice() {
  const { deviceId, setDeviceId } = useDeviceStore.getState()

  if (!deviceId) {
    const newDeviceId = crypto.randomUUID()
    setDeviceId(newDeviceId)
    setAmplitudeUserDevice(newDeviceId)
  }
}

/**
 * Sets the device ID in Amplitude.
 * @param installationToken - The device ID to set.
 */
export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitudeSetDeviceId(installationToken)
}
