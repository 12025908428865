import ReactPixel, {
  AdvancedMatching as ReactAdvancedMatching,
  Options as ReactOptions,
} from "react-facebook-pixel"

/**
 * Custom AdvancedMatching interface for Facebook Pixel.
 * Extends ReactAdvancedMatching to include only relevant fields.
 */
interface AdvancedMatching {
  em?: string
  [key: string]: unknown
}

/**
 * Initializes Facebook Pixel with optional user data for advanced matching.
 * @param user - Optional user object containing user information.
 */
export function initFacebookPixel(userEmail?: string): void {
  const pixelId: string | undefined = import.meta.env.VITE_FACEBOOK_PIXEL_ID

  if (!pixelId) {
    console.error("Missing Facebook Pixel ID")
    return
  }

  const advancedMatching: AdvancedMatching = userEmail ? { em: userEmail } : {}

  const options: ReactOptions = {
    autoConfig: true, // Automatically configures the pixel
    debug: false, // Disable debug mode
  }

  ReactPixel.init(
    pixelId,
    advancedMatching as unknown as ReactAdvancedMatching,
    options,
  )
  ReactPixel.pageView() // Track initial page view
}
