import { Input } from "@chakra-ui/react"

import type { QuestionProps } from "@bleu/front/components/forms/types"

import { QuestionWrapper } from "./QuestionWrapper"

interface DateQuestionProps extends QuestionProps<string> {
  title: string
  subtitle?: string
}

export const DateQuestion = ({
  title,
  subtitle,
  onChange,
  ...props
}: DateQuestionProps) => {
  return (
    <QuestionWrapper title={title} subtitle={subtitle}>
      <Input
        type="date"
        placeholder={title}
        borderRadius={4}
        borderColor="blue.300"
        onChange={(e) => onChange(e.target.value)}
        width="100%"
        {...props}
      />
    </QuestionWrapper>
  )
}
