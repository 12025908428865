import {
  CervicalCancerCheckTypeOption,
  CervicalCancerLastCheckOption,
} from "@bleu/shared/questionnaires/index"

import type { ComputePrescription } from "./types"

export const CERVICAL_CANCER_PRESCRIPTIONS = {
  cytologic: {
    shortName: "Frottis",
    title: "Faire réaliser un écouvillonnage du col de l’utérus",
    description:
      "pour examen cytologique dans le cadre d’un rattrapage du dépistage du cancer de l’utérus",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  hpvhr: {
    shortName: "HPV-HR",
    title: "Faire réaliser un prélèvement vaginal",
    description:
      "ou un auto-prélèvement selon préférence pour\n" +
      "recherche de HPV-HR dans le cadre d’un rattrapage du dépistage du cancer de l’utérus",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
  cervicalConsultation: {
    shortName: "Consult. cancer utérus",
    title: "Consultation dépistage col de l'utérus",
    prescriptionType: "PRESCRIPTION_ADDRESSING",
  },
} as const

export const cervicalCancerComputePrescription: ComputePrescription = (
  { cervicalCancer },
  { age },
) => {
  const { lastCheck, checkType, isFirstCheck } = cervicalCancer!

  if (age >= 25 && age < 30) {
    if (lastCheck === CervicalCancerLastCheckOption.NEVER)
      return [CERVICAL_CANCER_PRESCRIPTIONS.cytologic]
    if (isFirstCheck) {
      if (checkType === CervicalCancerCheckTypeOption.CYTOLOGY)
        return [CERVICAL_CANCER_PRESCRIPTIONS.cytologic]
      if (checkType === CervicalCancerCheckTypeOption.HPV)
        return [CERVICAL_CANCER_PRESCRIPTIONS.cervicalConsultation]
    }
    if (lastCheck !== CervicalCancerLastCheckOption.LESS_THAN_A_YEAR)
      return [CERVICAL_CANCER_PRESCRIPTIONS.cytologic]
  }

  if (
    [30, 31, 32].includes(age) &&
    isFirstCheck &&
    lastCheck !== CervicalCancerLastCheckOption.LESS_THAN_A_YEAR &&
    checkType === CervicalCancerCheckTypeOption.HPV
  ) {
    return [CERVICAL_CANCER_PRESCRIPTIONS.cervicalConsultation]
  }

  if (
    [33, 34].includes(age) &&
    isFirstCheck &&
    lastCheck === CervicalCancerLastCheckOption.MORE_THAN_5_YEARS &&
    checkType === CervicalCancerCheckTypeOption.HPV
  ) {
    return [CERVICAL_CANCER_PRESCRIPTIONS.cervicalConsultation]
  }

  if (age < 35) {
    if (lastCheck === CervicalCancerLastCheckOption.NEVER)
      return [CERVICAL_CANCER_PRESCRIPTIONS.hpvhr]
    if (isFirstCheck) {
      if (
        lastCheck !== CervicalCancerLastCheckOption.LESS_THAN_A_YEAR &&
        checkType === CervicalCancerCheckTypeOption.CYTOLOGY
      ) {
        return [CERVICAL_CANCER_PRESCRIPTIONS.hpvhr]
      }

      if (lastCheck === CervicalCancerLastCheckOption.BETWEEN_3_AND_5_YEARS) {
        return [CERVICAL_CANCER_PRESCRIPTIONS.hpvhr]
      }

      if (
        lastCheck === CervicalCancerLastCheckOption.MORE_THAN_5_YEARS &&
        checkType === CervicalCancerCheckTypeOption.HPV
      ) {
        return [CERVICAL_CANCER_PRESCRIPTIONS.hpvhr]
      }
    }
  }

  if (
    age >= 35 &&
    age < 65 &&
    isFirstCheck &&
    checkType === CervicalCancerCheckTypeOption.HPV &&
    [
      CervicalCancerLastCheckOption.NEVER,
      CervicalCancerLastCheckOption.MORE_THAN_5_YEARS,
    ].includes(lastCheck)
  ) {
    return [CERVICAL_CANCER_PRESCRIPTIONS.hpvhr]
  }

  return []
}
