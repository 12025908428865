import { create } from "zustand"
import { persist } from "zustand/middleware"

import { DepistageName } from "@bleu/shared/depistages"

interface DepistagesStore {
  excludedDepistages: DepistageName[]
  setExcludedDepistages: (depistages: DepistageName[]) => void
  prescribedDepistages: DepistageName[]
  setPrescribedDepistages: (depistages: DepistageName[]) => void
  reset: () => void
}

export const useDepistagesStore = create<DepistagesStore>()(
  persist(
    (set) => ({
      excludedDepistages: [],
      setExcludedDepistages: (depistages) =>
        set({ excludedDepistages: depistages }),
      prescribedDepistages: [],
      setPrescribedDepistages: (depistages) =>
        set({ prescribedDepistages: depistages }),
      reset: () => set({ excludedDepistages: [], prescribedDepistages: [] }),
    }),
    {
      name: "depistages-storage",
    },
  ),
)
