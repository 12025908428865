import { Dispatch, SetStateAction } from "react"
import { create } from "zustand"
import { persist } from "zustand/middleware"

import { DepistageName } from "@bleu/shared/depistages"
import {
  AllQuestionnairesAnswers,
  ProfileData,
} from "@bleu/shared/questionnaires"
import { VerticalName } from "@bleu/shared/verticals"

import { Steps } from "@bleu/front/components/funnelSteps/hooks"

interface FunnelStore {
  selectedVerticals: VerticalName[]
  profile: ProfileData | undefined
  excludedDepistages: DepistageName[]
  answers: AllQuestionnairesAnswers
  prescribedDepistages: DepistageName[]
  setSelectedVerticals: Dispatch<SetStateAction<VerticalName[]>>
  setProfile: Dispatch<SetStateAction<ProfileData | undefined>>
  setExcludedDepistages: Dispatch<SetStateAction<DepistageName[]>>
  setAnswers: Dispatch<SetStateAction<AllQuestionnairesAnswers>>
  setPrescribedDepistages: Dispatch<SetStateAction<DepistageName[]>>
  reset: () => void
  currentStep: Steps
  lastCompletedStep: Steps | null
  setCurrentStep: (step: Steps) => void
  setLastCompletedStep: (step: Steps | null) => void
  canProceedToStep: (step: Steps) => boolean
  previousStep: Steps | null
  goToPreviousStep: () => void
  setSelectedVerticalsAndDepistages: (vertical: VerticalName) => void
}

export const useFunnelStore = create<FunnelStore>()(
  persist(
    (set, get) => ({
      selectedVerticals: [],
      profile: undefined,
      excludedDepistages: [],
      answers: {},
      prescribedDepistages: [],
      lastCompletedStep: null,
      currentStep: Steps.start,
      setSelectedVerticals: (verticals) =>
        set((state) => ({
          selectedVerticals:
            typeof verticals === "function"
              ? verticals(state.selectedVerticals)
              : verticals,
        })),
      setProfile: (action) =>
        set((state) => ({
          profile:
            typeof action === "function" ? action(state.profile) : action,
        })),
      setExcludedDepistages: (action) =>
        set((state) => ({
          excludedDepistages:
            typeof action === "function"
              ? action(state.excludedDepistages)
              : action,
        })),
      setAnswers: (action) =>
        set((state) => ({
          answers:
            typeof action === "function" ? action(state.answers) : action,
        })),
      setPrescribedDepistages: (action) =>
        set((state) => ({
          prescribedDepistages:
            typeof action === "function"
              ? action(state.prescribedDepistages)
              : action,
        })),
      reset: () =>
        set({
          selectedVerticals: [],
          profile: undefined,
          excludedDepistages: [],
          answers: {},
          prescribedDepistages: [],
          lastCompletedStep: null,
          currentStep: Steps.start,
        }),
      setCurrentStep: (step) => set({ currentStep: step }),
      setLastCompletedStep: (step) => set({ lastCompletedStep: step }),
      canProceedToStep: (step: Steps) => {
        const { currentStep, lastCompletedStep } = get()
        if (step === Steps.start) return true
        if (lastCompletedStep === null) return step === Steps.profile // Allow proceeding to profile if no step completed
        const stepIndex = getStepIndex(step)
        const lastCompletedIndex = getStepIndex(lastCompletedStep)
        const currentIndex = getStepIndex(currentStep)
        return stepIndex <= lastCompletedIndex + 1 && stepIndex > currentIndex
      },
      previousStep: null,
      goToPreviousStep: () =>
        set((state) => {
          if (state.previousStep !== null) {
            return {
              currentStep: state.previousStep,
              previousStep: null, // Reset previous step after going back
            }
          }
          return state // If no previous step, don't change anything
        }),
      setSelectedVerticalsAndDepistages: (vertical: VerticalName) => {
        set({
          selectedVerticals: [vertical],
          excludedDepistages: [],
          lastCompletedStep: Steps.depistagesSelection,
          currentStep: Steps.profile,
        })
      },
    }),
    {
      name: "funnel-store",
      partialize: (state) => ({
        lastCompletedStep: state.lastCompletedStep,
        currentStep: state.currentStep,
        profile: state.profile,
        answers: state.answers,
        prescribedDepistages: state.prescribedDepistages,
        previousStep: state.previousStep,
      }),
    },
  ),
)

function getStepIndex(step: Steps): number {
  const stepOrder = [
    Steps.start,
    Steps.profile,
    Steps.depistagesSelection,
    Steps.questionnaires,
    Steps.recap,
    Steps.payment,
  ]
  return stepOrder.indexOf(step)
}
