import {
  Stack,
  Container,
  Button,
  Textarea,
  SimpleGrid,
  Switch,
  HStack,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { useMutation } from "@tanstack/react-query"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"

import {
  AdminRequests,
  AdminResponses,
  emailExtractionParamsSchema,
} from "@bleu/types/endpoints/admin"

import {
  DepistagesLongNames,
  DepistagesNames,
} from "@bleu/shared/depistages/index"
import { MEDICAL_RECORD_STATUSES } from "@bleu/shared/medicalRecords/statuses"

import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"
import { apiClient } from "@bleu/front/lib/apiClient"

interface ExtractedEmail {
  email: string
  name: string
}

export const EmailExtractionPage = () => {
  const [results, setResults] = useState<ExtractedEmail[]>([])

  const form = useForm<AdminRequests.ExtractEmails>({
    validate: zodResolver(emailExtractionParamsSchema),
    initialValues: {
      // @ts-expect-error createdBefore and createdAfter are not strings
      createdBefore: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
      // @ts-expect-error createdBefore and createdAfter are not strings
      createdAfter: dayjs().format("YYYY-MM-DD"),
      statuses: [],
      selectedDepistages: [],
      acceptsMarketing: false,
    },
  })

  const { mutateAsync: extractEmails, isPending } = useMutation({
    mutationFn: async () => {
      const response = await apiClient.post<AdminResponses.ExtractedEmails>(
        "/admin/emails",
        form.values,
      )
      return response.data
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      setResults(data.emails)
    },
  })

  const formatResults = (emails: ExtractedEmail[]) => {
    const header = "Name,Email\n"
    const rows = emails
      .map(({ email, name }) => `"${name || "Unknown"}","${email}"`)
      .join("\n")
    return header + rows
  }

  return (
    <Container maxW={1080}>
      <Stack gap={10}>
        <DoctorLayout.Title>Extraction d‘email</DoctorLayout.Title>
        <DoctorLayout.Body>
          <SimpleGrid columns={2} spacing={10}>
            <Stack gap={4}>
              <Heading>Filtres</Heading>
              <FormControl>
                <FormLabel fontWeight="bold">Date du dépistage</FormLabel>
                <HStack>
                  <Input type="date" {...form.getInputProps("createdBefore")} />
                  <Input type="date" {...form.getInputProps("createdAfter")} />
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bold">Statut du dossier</FormLabel>
                <CheckboxGroup {...form.getInputProps("statuses")}>
                  <Stack>
                    {_.map(MEDICAL_RECORD_STATUSES, (info, status) => (
                      <Checkbox value={status}>{info.label}</Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bold">Dépistages sélectionnés</FormLabel>
                <CheckboxGroup {...form.getInputProps("selectedDepistages")}>
                  <Stack>
                    {_.map(DepistagesNames, (depistageName) => (
                      <Checkbox value={depistageName}>
                        {DepistagesLongNames[depistageName]}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel fontWeight="bold" htmlFor="acceptsMarketing" mb={0}>
                  Communication marketing
                </FormLabel>
                <Switch
                  id="acceptsMarketing"
                  {...form.getInputProps("acceptsMarketing", {
                    type: "checkbox",
                  })}
                />
              </FormControl>
              <Button
                mt={4}
                isLoading={isPending}
                onClick={() => extractEmails()}
                isDisabled={!form.isValid()}
              >
                Extraire les emails
              </Button>
            </Stack>
            <Stack gap={4}>
              <Heading>Résultats ({results.length})</Heading>
              <Textarea
                h="100%"
                value={formatResults(results)}
                resize="vertical"
                isReadOnly
              />
            </Stack>
          </SimpleGrid>
        </DoctorLayout.Body>
      </Stack>
    </Container>
  )
}
