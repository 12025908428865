import { Center } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

export const AuthLayout = () => (
  <Center
    w="100vw"
    h="100vh"
    bgRepeat="no-repeat"
    bgImage="/img/dots-background-2.webp"
    bgSize="cover"
  >
    <Outlet />
  </Center>
)
