import {
  Box,
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { IconMessageChatbot, IconPhoneOutgoing } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import type { MedicalRecordResponses } from "@bleu/types/endpoints"

import {
  isMedicalRecordStatusBefore,
  type MedicalRecordStatus,
  MedicalRecordStatuses,
} from "@bleu/shared/medicalRecords/statuses"

import { CalendlyButton } from "@bleu/front/components/CalendlyButton"
import { ConversationChat } from "@bleu/front/components/ConversationChat"
import { PlaceholderChatInterface } from "@bleu/front/components/PlaceholderChat"
import { RedDot } from "@bleu/front/components/RedDot"
import { Score2Card } from "@bleu/front/components/Score2Card"
import PatientHomeLayout from "@bleu/front/components/layout/PatientHomeLayout"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import {
  Timeline,
  type TimelineItem,
} from "@bleu/front/components/patientSpace/Timeline"
import { ConversationProvider } from "@bleu/front/components/seald/ConversationProvider"
import { DownloadDocumentButton } from "@bleu/front/pages/DownloadDocumentButton"
import { UploadDocumentButton } from "@bleu/front/pages/UploadDocumentButton"
import { useMedicalRecordPageDataQuery } from "@bleu/front/queries/medicalRecords"

import { useAmplitudeTrack } from "../utils/tracking"

export const MedicalRecordPage = () => {
  const { medicalRecordId } = useParams()
  if (!medicalRecordId) {
    throw new Error("No medical medicalRecord id provided")
  }

  const medicalRecordPageDataQuery =
    useMedicalRecordPageDataQuery(medicalRecordId)

  return (
    <QueryWrapper query={medicalRecordPageDataQuery}>
      {({ data }) => <_MedicalRecordPage medicalRecord={data} />}
    </QueryWrapper>
  )
}

interface Props {
  medicalRecord: MedicalRecordResponses.MedicalRecordPageData
}

const _MedicalRecordPage = ({ medicalRecord }: Props) => {
  const navigate = useNavigate()
  const { track } = useAmplitudeTrack()

  useEffect(() => {
    track("Medical Record Viewed", { recordCode: medicalRecord.code })
  }, [medicalRecord.code, track])

  const getTimelineItems = (status: MedicalRecordStatus): TimelineItem[] => {
    return [
      {
        disabled: false,
        title: "Réception de vos réponses",
        subtitle: "Vos réponses sont en cours d'analyse par un médecin Bleu.",
      },
      {
        disabled: false,
        title: "Echangez avec un médecin Bleu",
        subtitle: medicalRecord.phoneCall
          ? medicalRecord.phoneCall.isCallDone
            ? `Votre rendez-vous a eu lieu le ${dayjs(medicalRecord.phoneCall.date).format("LL [à] HH[h]mm")}`
            : `Vous avez rendez-vous le ${dayjs(medicalRecord.phoneCall.date).format("LL [à] HH[h]mm")}`
          : "Vous pouvez planifier un appel ou modifier votre rendez-vous existant",
        content: medicalRecord.phoneCall ? (
          medicalRecord.phoneCall.isCallDone ? undefined : (
            <Button
              size="lg"
              leftIcon={<IconPhoneOutgoing />}
              variant="outline"
              as="a"
              href={medicalRecord.phoneCall.rescheduleUrl}
              onClick={() => {
                track("Meeting Reschedule Link Clicked", {
                  recordCode: medicalRecord.code,
                })
              }}
              target="_blank"
            >
              Décaler mon rendez-vous
            </Button>
          )
        ) : (
          <CalendlyButton medicalRecordCode={medicalRecord.code} />
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_PRESCRIPTION,
        ),
        title:
          medicalRecord.documents.length > 0
            ? "Vos ordonnances sont prêtes"
            : "Votre ordonnance est prête",
        content: (
          <Stack>
            {medicalRecord.documents
              .filter((doc) => doc.type.includes("PRESCRIPTION"))
              .map((doc) => (
                <DownloadDocumentButton
                  key={doc.id}
                  doc={doc}
                  isDisabled={isMedicalRecordStatusBefore(
                    status,
                    MedicalRecordStatuses.PENDING_PRESCRIPTION,
                  )}
                />
              ))}
          </Stack>
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_LAB_RESULTS,
          true,
        ),
        title: "Envoyez vos résultats",
        subtitle:
          "Envoyez un fichier en clair ou indiquez-nous le mot de passe",
        content: (
          <Stack>
            {medicalRecord.documents
              .filter((doc) => doc.type === "LAB_RESULTS")
              .map((doc) => (
                <DownloadDocumentButton
                  key={doc.id}
                  doc={doc}
                  isDisabled={isMedicalRecordStatusBefore(
                    status,
                    MedicalRecordStatuses.PENDING_PRESCRIPTION,
                  )}
                />
              ))}
            <UploadDocumentButton medicalRecord={medicalRecord} />
          </Stack>
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS,
          true,
        ),
        title: "Votre analyse par l'équipe médicale Bleu",
        subtitle:
          status === MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS
            ? "Votre analyse sera bientôt disponible dans la messagerie sécurisée"
            : "Votre analyse est disponible dans la messagerie sécurisée",
      },
    ]
  }

  const timelineItems = getTimelineItems(medicalRecord.status)

  return (
    <>
      <PatientHomeLayout.Header onBack={() => navigate("/")} />
      <VSpacer size={4} />
      <HStack justifyContent="space-between" width="100%">
        <Text color="navy.300">N°{medicalRecord.code}</Text>
        <Button
          as={Link}
          to="answers"
          textTransform="none"
          variant="link"
          size="sm"
          color="blue.400"
        >
          Mes réponses aux questions
        </Button>
      </HStack>
      <VSpacer size={5} />
      <Divider borderColor="gray.300" marginX={-20} />
      <VSpacer size={6} />
      <HStack id="test" alignItems="start" gap={8}>
        <VStack alignItems="stretch">
          {medicalRecord.score2 && <Score2Card score2={medicalRecord.score2} />}
          <Timeline items={timelineItems} flex={1} />
        </VStack>
        <VStack
          h="min-content"
          flex={2}
          display={["none", "flex"]}
          className="h-full"
        >
          {medicalRecord.conversationId ? (
            <ConversationProvider conversationId={medicalRecord.conversationId}>
              {({ conversation, sealdSession }) => (
                <ConversationChat
                  isDisabled={
                    medicalRecord.status === MedicalRecordStatuses.CLOSED
                  }
                  conversation={conversation}
                  sealdSession={sealdSession}
                />
              )}
            </ConversationProvider>
          ) : (
            <PlaceholderChatInterface>
              <VStack gap={4} justifyContent="center" h="100%">
                <Text width="50%" textAlign="center" fontWeight="bold">
                  Un médecin Bleu va bientôt vous contacter pour discuter de
                  votre dossier
                </Text>
              </VStack>
            </PlaceholderChatInterface>
          )}
        </VStack>
      </HStack>
      <VStack
        display={["flex", "none"]}
        alignItems="stretch"
        position="sticky"
        bottom={10}
      >
        <VSpacer size={20} />
        <Button
          as={Link}
          to="conversation"
          variant="outline"
          leftIcon={
            <Box position="relative">
              <IconMessageChatbot />
              {medicalRecord.hasNewMessage && <RedDot />}
            </Box>
          }
        >
          Accès à la messagerie
        </Button>
      </VStack>
    </>
  )
}
