import {
  Box,
  Text,
  HStack,
  useBreakpointValue,
  Flex,
  Icon,
  Heading,
} from "@chakra-ui/react"
import { CheckIcon } from "@heroicons/react/24/solid"
import React from "react"

const allStepNames = [
  "Début",
  "Profil",
  "Dépistages",
  "Questionnaires",
  "Récapitulatif",
  "Paiement",
]

interface StepIndicatorProps {
  currentStep: number
  totalSteps: number
  showDepistagesStep: boolean
  completedStepIcon?: React.ReactElement
}

export function StepIndicator({
  currentStep,
  showDepistagesStep,
  completedStepIcon,
}: StepIndicatorProps) {
  const isMobile = useBreakpointValue({ base: true, md: false })

  const stepNames = React.useMemo(() => {
    return showDepistagesStep
      ? allStepNames
      : allStepNames.filter((step) => step !== "Dépistages")
  }, [showDepistagesStep])

  const adjustedCurrentStep = React.useMemo(() => {
    if (!showDepistagesStep && currentStep > 1) {
      return currentStep - 1
    }
    return currentStep
  }, [showDepistagesStep, currentStep])

  if (isMobile) {
    return (
      <Text fontSize="sm" fontWeight="medium" color="navy.400">
        Étape {adjustedCurrentStep + 1}/{stepNames.length} -{" "}
        {stepNames[adjustedCurrentStep]}
      </Text>
    )
  }

  return (
    <HStack spacing={2} width="100%">
      {stepNames.map((step, index) => (
        <Box
          key={index}
          flex={1}
          py={2}
          px={4}
          textAlign="center"
          fontSize="sm"
          fontWeight="medium"
          bg={
            index === adjustedCurrentStep
              ? "blue.400"
              : index < adjustedCurrentStep
                ? "blue.200"
                : "gray.200"
          }
          color={
            index === adjustedCurrentStep
              ? "white"
              : index < adjustedCurrentStep
                ? "navy.400"
                : "gray.500"
          }
          borderRadius="md"
          border="1px solid"
          borderColor={
            index === adjustedCurrentStep
              ? "blue.500"
              : index < adjustedCurrentStep
                ? "blue.300"
                : "gray.300"
          }
          boxShadow={index === adjustedCurrentStep ? "md" : "none"}
          transition="all 0.3s ease-in-out"
        >
          <Flex alignItems="center" justifyContent="center">
            {index < adjustedCurrentStep && completedStepIcon ? (
              React.cloneElement(completedStepIcon, {
                mr: 2,
                color: "blue.400",
              })
            ) : index < adjustedCurrentStep ? (
              <Icon as={CheckIcon} color="blue.400" mr={2} />
            ) : null}
            <Heading as="h2" size="sm" color="inherit">
              {step}
            </Heading>
          </Flex>
        </Box>
      ))}
    </HStack>
  )
}
