import { useEffect } from "react"

import { ProfileData } from "@bleu/shared/questionnaires"

import { useDepistagesStore, useFunnelStore } from "@bleu/front/lib/stores"

import { useDepistages } from "./useDepistages"

/**
 * Initializes depistages based on the user's profile data.
 *
 * @param profile - The user's profile data, which may be undefined.
 * @returns An object containing the prescribed depistages, excluded depistages, and a setter for excluded depistages.
 */
export const useInitializeDepistages = (profile: ProfileData | undefined) => {
  const { relevantDepistages } = useDepistages()
  const { excludedDepistages, setExcludedDepistages } = useDepistagesStore()
  const { prescribedDepistages, setPrescribedDepistages } = useFunnelStore()

  useEffect(() => {
    if (profile && relevantDepistages.length > 0) {
      const initialPrescribedDepistages = relevantDepistages
        .filter((depistage) => !excludedDepistages.includes(depistage.name))
        .map((depistage) => depistage.name)

      setPrescribedDepistages(initialPrescribedDepistages)
    }
  }, [profile, relevantDepistages, excludedDepistages, setPrescribedDepistages])

  return { prescribedDepistages, excludedDepistages, setExcludedDepistages }
}
