import { SignIn, SignUp } from "@clerk/clerk-react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError,
} from "react-router-dom"

import { UserRole } from "@bleu/types/user"

import { AuthGuard } from "@bleu/front/components/auth/AuthGuard"
import { DoctorGuard } from "@bleu/front/components/auth/DoctorGuard"
import { RoleGuard } from "@bleu/front/components/auth/RoleGuard"
import { AppLayout } from "@bleu/front/components/layout/AppLayout"
import { AuthLayout } from "@bleu/front/components/layout/AuthLayout"
import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"
import PatientHomeLayout from "@bleu/front/components/layout/PatientHomeLayout"
import { TrackingWrapper } from "@bleu/front/components/layout/TrackingWrapper"

import { ErrorBoundaryFallback } from "../ErrorBoundaryFallback"
import { PaymentFailed } from "../components/funnelSteps/Payment/PaymentFailed"
import { PaymentSuccess } from "../components/funnelSteps/Payment/PaymentSuccess"
import QuestionnaireLayout from "../components/layout/QuestionnaireLayout"

import { AnswersPage } from "./AnswersPage"
import { ConversationPage } from "./ConversationPage"
import { EmailExtractionPage } from "./EmailExtractionPage"
import { FunnelPage } from "./FunnelPage"
import { MedicalRecordPage } from "./MedicalRecordPage"
import { NotFoundPage } from "./NotFoundPage"
import { PatientHomePage } from "./PatientHomePage"
import { AccountLayout } from "./account/AccountLayout"
import { EditPreferenceLayout } from "./account/EditPreferenceLayout"
import { InvoicesPanel } from "./account/InvoicesPanel"
import { PreferencesPanel } from "./account/PreferencesPanel"
import { Success } from "./account/Success"
import { UpdateEmailAddress } from "./account/UpdateEmailAddress"
import { UpdatePassword } from "./account/UpdatePassword"
import { UpdatePhoneNumber } from "./account/UpdatePhoneNumber"
import { DoctorHomePage } from "./doctor/DoctorHomePage"
import { LabResultsAnalysisPage } from "./doctor/LabResultsAnalysisPage"
import { MedicalRecordConversationPage } from "./doctor/MedicalRecordConversationPage"
import { MedicalRecordDocumentsPage } from "./doctor/MedicalRecordDocumentsPage"
import { MedicalRecordHomePage } from "./doctor/MedicalRecordHomePage"
import { MedicalRecordNotesPage } from "./doctor/MedicalRecordNotesPage"
import { MedicalRecordQuestionnaireAnswersPage } from "./doctor/MedicalRecordQuestionnaireAnswersPage"
import { NewDoctorPage } from "./doctor/NewDoctorPage"
import { PatientFileLayout } from "./doctor/PatientFileLayout"
import { PatientMedicalRecordLayout } from "./doctor/PatientMedicalRecordLayout"
import { PrescriptionPage } from "./doctor/PrescriptionPage"

// eslint-disable-next-line react-refresh/only-export-components
const RouteErrorBoundary = () => {
  const error = useRouteError() as Error
  return (
    <ErrorBoundaryFallback
      error={error}
      resetErrorBoundary={() => window.location.reload()}
    />
  )
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<TrackingWrapper />} errorElement={<RouteErrorBoundary />}>
      <Route element={<AuthLayout />} errorElement={<RouteErrorBoundary />}>
        <Route path="sign-in/*" element={<SignIn routing="virtual" />} />
        <Route path="sign-up/*" element={<SignUp routing="virtual" />} />
      </Route>
      <Route element={<AppLayout />} errorElement={<RouteErrorBoundary />}>
        <Route index element={<PatientHomePage />} />
      </Route>
      <Route
        element={<QuestionnaireLayout />}
        errorElement={<RouteErrorBoundary />}
      >
        <Route path="questionnaire" element={<FunnelPage />} />
      </Route>
      <Route element={<AuthGuard />} errorElement={<RouteErrorBoundary />}>
        <Route element={<AppLayout />}>
          <Route path="payment-success" element={<PaymentSuccess />} />
          <Route path="payment-failed" element={<PaymentFailed />} />
        </Route>
        <Route element={<PatientHomeLayout />}>
          <Route path="account" element={<AccountLayout />}>
            <Route path="preferences" element={<EditPreferenceLayout />}>
              <Route index element={<PreferencesPanel />} />
              <Route path="email" element={<UpdateEmailAddress />} />
              <Route path="phone" element={<UpdatePhoneNumber />} />
              <Route path="password" element={<UpdatePassword />} />
              <Route path="success" element={<Success />} />
            </Route>
            <Route path="invoices" element={<InvoicesPanel />} />
          </Route>
          <Route path="/prescriptions/:medicalRecordId">
            <Route index element={<MedicalRecordPage />} />
            <Route path="conversation" element={<ConversationPage />} />
            <Route path="answers" element={<AnswersPage />} />
          </Route>
        </Route>
        <Route element={<RoleGuard role={UserRole.DOCTOR} />}>
          <Route element={<DoctorGuard />}>
            <Route path="doctor" element={<DoctorLayout />}>
              <Route index element={<DoctorHomePage />} />
              <Route element={<RoleGuard role={UserRole.ADMIN} />}>
                <Route path="new-doctor" element={<NewDoctorPage />} />
                <Route
                  path="email-extraction"
                  element={<EmailExtractionPage />}
                />
              </Route>
              <Route path="patients/:patientId" element={<PatientFileLayout />}>
                <Route
                  path="records/:recordId"
                  element={<PatientMedicalRecordLayout />}
                >
                  <Route index element={<MedicalRecordHomePage />} />
                  <Route path="home" element={<MedicalRecordHomePage />} />
                  <Route
                    path="answers"
                    element={<MedicalRecordQuestionnaireAnswersPage />}
                  />
                  <Route path="notes" element={<MedicalRecordNotesPage />} />
                  <Route
                    path="documents"
                    element={<MedicalRecordDocumentsPage />}
                  />
                  <Route
                    path="conversation"
                    element={<MedicalRecordConversationPage />}
                  />
                </Route>
              </Route>
              <Route
                path="patients/:patientId/records/:recordId/prescription/:prescriptionType?"
                element={<PrescriptionPage />}
              />
              <Route
                path="patients/:patientId/records/:recordId/lab-results/:documentId"
                element={<LabResultsAnalysisPage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route element={<AppLayout />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>,
  ),
)
