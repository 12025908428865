import { Button, Spinner } from "@chakra-ui/react"
import { IconDownload } from "@tabler/icons-react"

import type { Document } from "@bleu/types/prisma-client"

import { useDownloadFileMutation } from "@bleu/front/queries/files"
import { useAmplitudeTrack } from "@bleu/front/utils/tracking"

type Props = {
  doc: Document
  isDisabled: boolean
}

export const DownloadDocumentButton = ({ doc, isDisabled }: Props) => {
  const { mutateAsync: downloadFile, isPending } = useDownloadFileMutation()
  const { track } = useAmplitudeTrack()

  const handleClick = async () => {
    await downloadFile({ filePath: doc.filePath, filename: doc.name })
    track("Document Downloaded", {
      docType: doc.type,
    })
  }

  return (
    <Button
      key={doc.id}
      variant="ghost"
      size="xs"
      leftIcon={
        isPending ? (
          <Spinner color="blue.400" size="xs" />
        ) : (
          <IconDownload size={16} />
        )
      }
      color="blue.400"
      isDisabled={isDisabled}
      onClick={handleClick}
    >
      {doc.name}
    </Button>
  )
}
