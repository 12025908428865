import { Box, Container } from "@chakra-ui/react"
import { Crisp } from "crisp-sdk-web"
import { useEffect } from "react"
import { Outlet } from "react-router-dom"

import { Header } from "@bleu/front/components/layout/Header"

export const AppLayout = () => {
  useEffect(() => {
    Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID)
  }, [])

  return (
    <Box
      bgRepeat="no-repeat"
      bgImage="/img/dots-background-corner.webp"
      bgSize="cover"
    >
      <Header />
      <Container maxW="container.xl" mb={8} px={5}>
        <Outlet />
      </Container>
    </Box>
  )
}
