import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  Spinner,
  useDisclosure,
  Text,
} from "@chakra-ui/react"
import { IconUpload } from "@tabler/icons-react"
import { useRef, useState } from "react"

import { MedicalRecordResponses } from "@bleu/types/endpoints/index"

import {
  isMedicalRecordStatusBefore,
  MedicalRecordStatuses,
} from "@bleu/shared/medicalRecords/statuses"

import { useUploadLabResultsMutation } from "@bleu/front/queries/medicalRecords"
import { useAmplitudeTrack } from "@bleu/front/utils/tracking"

type Props = {
  medicalRecord: MedicalRecordResponses.MedicalRecordPageData
}

export const UploadDocumentButton = ({ medicalRecord }: Props) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [file, setFile] = useState<File | null>(null)
  const [showPasswordInput, setShowPasswordInput] = useState(false)
  const [documentPassword, setDocumentPassword] = useState<string>()

  const { mutateAsync: uploadFile, isPending: isUploadingLabResults } =
    useUploadLabResultsMutation(medicalRecord.id)

  const { track } = useAmplitudeTrack()

  const handleUpload = async () => {
    onClose()
    setShowPasswordInput(false)
    await uploadFile({
      file,
      password: documentPassword,
      doctorId: "User upload",
    })
    track("Lab Results Uploaded", {
      recordCode: medicalRecord.code,
    })
  }

  return (
    <>
      <Button
        as="label"
        htmlFor="results-upload"
        variant="ghost"
        size="xs"
        leftIcon={
          isUploadingLabResults ? (
            <Spinner color="blue.400" size="xs" />
          ) : (
            <IconUpload size={16} />
          )
        }
        color="blue.400"
        isDisabled={isMedicalRecordStatusBefore(
          medicalRecord.status,
          MedicalRecordStatuses.PENDING_LAB_RESULTS,
          true,
        )}
      >
        Envoyer mes résultats
      </Button>
      <Input
        id="results-upload"
        display="none"
        type="file"
        onChange={async (e) => {
          setFile(e.target.files?.item(0) ?? null)
          onOpen()
        }}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Protection de votre document
            </AlertDialogHeader>

            <AlertDialogBody>
              {showPasswordInput ? (
                <Input
                  value={documentPassword}
                  placeholder="Mot de passe du document"
                  onChange={(e) => setDocumentPassword(e.target.value)}
                />
              ) : (
                <Text>Votre document est-il protégé par un mot de passe ?</Text>
              )}
            </AlertDialogBody>

            {!showPasswordInput ? (
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={handleUpload}
                  variant="outline"
                >
                  Non
                </Button>
                <Button onClick={() => setShowPasswordInput(true)} ml={3}>
                  Oui
                </Button>
              </AlertDialogFooter>
            ) : (
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => setShowPasswordInput(false)}
                  variant="outline"
                >
                  Retour
                </Button>
                <Button onClick={handleUpload} ml={3}>
                  Enregistrer le mot de passe
                </Button>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
