import { Link } from "react-router-dom"

export const NotFoundPage = () => (
  <div className="flex flex-col items-center justify-center min-h-screen px-4 py-8 bg-gray-100">
    <img src="/img/404-monster.webp" alt="404" className="max-w-[350px] mb-6" />
    <h1 className="text-4xl font-bold text-center mb-16">
      Nous sommes navrés, mais cette page n&apos;existe pas.
    </h1>
    <Link
      to="/"
      className="px-6 py-3 text-lg font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-300"
    >
      Retour à l&apos;accueil
    </Link>
    <p className="mt-6 text-sm text-center">
      Si le problème persiste, contactez-nous à<br />
      <a
        href="mailto:contact@bleu.care"
        className="text-blue-600 hover:underline"
      >
        contact@bleu.care
      </a>
    </p>
  </div>
)
