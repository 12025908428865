import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import * as Sentry from "@sentry/react"
import _ from "lodash"
import { useMemo } from "react"
import { z } from "zod"

import { SealdResponses } from "@bleu/types/endpoints/seald"

import { PlaceholderChatInterface } from "@bleu/front/components/PlaceholderChat"
import { apiClient } from "@bleu/front/lib/apiClient"
import { queryClient } from "@bleu/front/lib/queryClient"
import { sealdClient } from "@bleu/front/lib/seald"
import {
  useSealdChallengeMutation,
  useSealdCompleteSignUpMutation,
} from "@bleu/front/queries/seald"

type Props = {
  userId: string
  email: string
  sealdId?: string
  twoManRuleChallenge: SealdResponses.TwoManRuleChallenge
  onChallengeSuccess?: () => void
  showChatPlaceHolder?: boolean // TODO: probably better to have something more integrated with SealdProvider
}

const challengeSchema = z.object({
  challenge: z
    .string()
    .length(8)
    .regex(
      /^[abcdefghjkmnpqrstuvwxyz23456789]{8}$/,
      " : format du code incorrect, vérifiez vos emails.",
    ),
})

export const SealdChallengeForm = ({
  userId,
  email,
  sealdId,
  onChallengeSuccess,
  twoManRuleChallenge,
  showChatPlaceHolder = true,
}: Props) => {
  const form = useForm<{ challenge: string }>({
    initialValues: { challenge: "" },
    validate: zodResolver(challengeSchema),
    validateInputOnBlur: true,
  })

  const {
    mutateAsync: sendChallenge,
    isPending: isSendingChallenge,
    error,
    isSuccess,
  } = useSealdChallengeMutation()

  const { mutateAsync: completeSealdSignUp } = useSealdCompleteSignUpMutation()

  const handleSendChallenge = async (challenge: string) => {
    if (!challenge || isSendingChallenge) return

    try {
      const authenticatedSealdSessionId = await sendChallenge({
        challenge: challenge.toLowerCase(),
        ...twoManRuleChallenge,
      })

      if (twoManRuleChallenge.isSignUp) {
        const factorToken = await sealdClient.ssks2MR.getFactorToken({
          sessionId: authenticatedSealdSessionId,
          authFactor: {
            type: "EM",
            value: email,
          },
        })

        const tmrAccesses = await apiClient.get<SealdResponses.TMRAccess[]>(
          "/seald/tmr-accesses",
        )

        await Promise.all(
          tmrAccesses.data.map(async (tmrAccess) => {
            const conversionResult = await sealdClient.convertTmrAccesses(
              factorToken.token,
              tmrAccess.sealdRawOverEncryptionKey,
              {
                sessionId: tmrAccess.sealdSessionId,
                tmrAccessId: tmrAccess.sealdTwoManRuleAccessId,
              },
            )

            if (conversionResult.succeeded[tmrAccess.sealdTwoManRuleAccessId]) {
              await apiClient.delete(
                `/conversations/${tmrAccess.conversationId}/invitations/${userId}`,
              )
            }

            if (!_.isEmpty(conversionResult.errored)) {
              Sentry.captureMessage("TMR Conversion error", {
                extra: conversionResult,
              })
              throw new Error("TMR Conversion error")
            }
          }),
        )
      }

      if (onChallengeSuccess) {
        onChallengeSuccess()
      }

      if (sealdId) {
        await completeSealdSignUp(sealdId)
      }

      await queryClient.invalidateQueries({
        queryKey: ["seald", userId],
      })
    } catch (err: unknown) {
      console.error(err)
      form.setErrors({
        challenge: "OTP incorrect. Veuillez vérifier votre code et réessayer.",
      })
    }
  }

  const Wrapper = useMemo(
    () => (showChatPlaceHolder ? PlaceholderChatInterface : Stack),
    [showChatPlaceHolder],
  )

  return (
    <Wrapper>
      <Stack
        gap={10}
        h="100%"
        justifyContent="space-around"
        alignItems="center"
      >
        <form
          onSubmit={form.onSubmit((values) =>
            handleSendChallenge(values.challenge),
          )}
        >
          <Stack
            gap={4}
            bg="white"
            p={6}
            mx={4}
            boxShadow="md"
            borderRadius={8}
            maxW={300}
          >
            <Heading>Authentification</Heading>
            <Text mb={8}>
              Saisissez le code reçu sur{" "}
              <Text as="span" fontWeight={600}>
                {email}
              </Text>{" "}
              pour accéder au contenu sécurisé.
            </Text>
            <FormControl isInvalid={!!form.errors.challenge}>
              <Input
                placeholder="Saisissez le code"
                {...form.getInputProps("challenge")}
                borderColor={form.errors.challenge ? "red.500" : undefined}
              />
              <FormErrorMessage fontSize="xs">
                {form.errors.challenge
                  ? form.errors.challenge
                  : error
                    ? "Une erreur est survenue"
                    : null}
              </FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              isLoading={isSendingChallenge}
              isDisabled={!form.isValid() || isSuccess}
            >
              Envoyer
            </Button>
          </Stack>
        </form>
      </Stack>
    </Wrapper>
  )
}
