import { create } from "zustand"
import { persist } from "zustand/middleware"

interface UTMState {
  utmSource: string | null
  utmMedium: string | null
  utmCampaign: string | null
  utmContent: string | null
  setUTMParams: (params: Partial<UTMState>) => void
  clearUTMParams: () => void
}

export const useUTMStore = create<UTMState>()(
  persist(
    (set) => ({
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmContent: null,
      setUTMParams: (params) => set(params),
      clearUTMParams: () =>
        set({
          utmSource: null,
          utmMedium: null,
          utmCampaign: null,
          utmContent: null,
        }),
    }),
    {
      name: "utm-storage",
    },
  ),
)
