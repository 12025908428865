import "./TipTap.css"

import {
  Box,
  Divider,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
  type ChakraProps,
  type StackProps,
} from "@chakra-ui/react"
import { IconSend } from "@tabler/icons-react"
import BulletList from "@tiptap/extension-bullet-list"
import OrderedList from "@tiptap/extension-ordered-list"
import { EditorContent, JSONContent, useEditor, Editor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useUser } from "@bleu/front/components/auth/UserContext"
import {
  groupMessagesByDateAndAuthor,
  isSameDate,
} from "@bleu/front/components/utils"
import dayjs from "@bleu/front/lib/dayjs"
import { type DecryptedConversation } from "@bleu/front/queries/seald"

import { colors } from "../assets/theme/colors"

import { TiptapEditor } from "./TipTapEditor"

const LAST_MESSAGE_BUBBLE_ID = "LAST_MESSAGE_BUBBLE"

const listConfig = {
  HTMLAttributes: {
    class: "ml-4",
  },
}

/**
 * Computes the border radii for a message bubble based on its position and author.
 *
 * @param isFirst - Whether the message is the first in the group.
 * @param isLast - Whether the message is the last in the group.
 * @param isUserAuthor - Whether the message was authored by the user.
 * @returns The computed border radii.
 */
const computeBorderRadii = (
  isFirst: boolean,
  isLast: boolean,
  isUserAuthor: boolean,
): ChakraProps => ({
  borderRadius: 20,
  ...(isFirst && {
    [isUserAuthor ? "borderBottomRightRadius" : "borderBottomLeftRadius"]: 4,
  }),
  ...(isLast &&
    !isFirst && {
      [isUserAuthor ? "borderTopRightRadius" : "borderTopLeftRadius"]: 4,
    }),
  ...(!isFirst &&
    !isLast && {
      [isUserAuthor ? "borderRightRadius" : "borderLeftRadius"]: 4,
    }),
})

type Message = DecryptedConversation["decryptedMessages"][number]

interface MessageBubbleProps extends StackProps {
  isUserAuthor: boolean
  message: Message
  isFirst: boolean
  isLast: boolean
}

/**
 * A component representing a single message bubble in the chat interface.
 *
 * @param message - The message content.
 * @param isUserAuthor - Whether the message was authored by the user.
 * @param isFirst - Whether the message is the first in the group.
 * @param isLast - Whether the message is the last in the group.
 * @param props - Additional props for the component.
 * @returns The rendered message bubble component.
 */
const MessageBubble: React.FC<MessageBubbleProps> = React.memo(
  ({ message, isUserAuthor, isFirst, isLast, ...props }) => {
    const borders = useMemo(
      () => computeBorderRadii(isFirst, isLast, isUserAuthor),
      [isFirst, isLast, isUserAuthor],
    )

    const extensions = useMemo(
      () => [
        StarterKit.configure({
          heading: false,
          bulletList: false,
          orderedList: false,
        }),
        BulletList.configure({
          ...listConfig,
          HTMLAttributes: {
            ...listConfig.HTMLAttributes,
            class: "list-disc ml-4",
          },
        }),
        OrderedList.configure({
          ...listConfig,
          HTMLAttributes: {
            ...listConfig.HTMLAttributes,
            class: "list-decimal ml-4",
          },
        }),
      ],
      [],
    )

    const editor = useEditor({
      extensions,
      content:
        typeof message.message === "string" ? message.message : message.message,
      editable: false,
    })

    return (
      <HStack
        {...props}
        justifyContent={isUserAuthor ? "flex-end" : "flex-start"}
        pl={10}
        pr={7}
      >
        <VStack maxW={["80%", "45%"]} alignItems="stretch" gap={0}>
          {isFirst && (
            <HStack justifyContent={isUserAuthor ? "end" : "start"}>
              <Text size="xs" fontWeight={600}>
                {message.authorFirstName}
              </Text>
            </HStack>
          )}
          <Box
            whiteSpace="pre-line"
            bgColor={isUserAuthor ? "blue.400" : "gray.200"}
            p={4}
            {...borders}
          >
            <Box color={isUserAuthor ? "white" : "navy"} fontSize="sm">
              <EditorContent editor={editor} className="message-content" />
            </Box>
          </Box>
          {isLast && (
            <HStack
              justifyContent={isUserAuthor ? "flex-end" : "flex-start"}
              marginTop={1}
            >
              <Text color="gray.600" size="xs">
                {isUserAuthor ? "Envoyé" : "Reçu"} à{" "}
                {dayjs(message.date).format("HH:mm")}
              </Text>
            </HStack>
          )}
        </VStack>
      </HStack>
    )
  },
)

MessageBubble.displayName = "MessageBubble"

const scrollbarStyles: ChakraProps["css"] = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.blue[400],
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: colors.blue[200],
    borderRadius: "8px",
  },
}

interface ConversationProps extends StackProps {
  messages: Message[]
  onPostMessage: (message: JSONContent) => Promise<void>
  isPostingMessage: boolean
  isDisabled?: boolean
}

/**
 * The main chat interface component.
 *
 * @param messages - The list of messages in the conversation.
 * @param onPostMessage - Function to handle posting a new message.
 * @param isPostingMessage - Whether a message is currently being posted.
 * @param isDisabled - Whether the chat interface is disabled.
 * @param props - Additional props for the component.
 * @returns The rendered chat interface component.
 */
export const ChatInterface: React.FC<ConversationProps> = React.memo(
  ({
    messages,
    isPostingMessage,
    onPostMessage,
    isDisabled = false,
    ...props
  }) => {
    const [editorInstance, setEditorInstance] = useState<Editor | null>(null)
    const [isEditorEmpty, setIsEditorEmpty] = useState(true)
    const user = useUser()
    const groupedMessages = useMemo(
      () => groupMessagesByDateAndAuthor(messages),
      [messages],
    )

    const handleSendMessage = useCallback(async () => {
      if (editorInstance && !isEditorEmpty) {
        const content = editorInstance.getJSON()
        await onPostMessage(content)
        editorInstance.commands.clearContent()
        setIsEditorEmpty(true)
      }
    }, [editorInstance, isEditorEmpty, onPostMessage])

    const handleEditorReady = useCallback((editor: Editor) => {
      setEditorInstance(editor)
      editor.on("update", () => {
        setIsEditorEmpty(editor.isEmpty)
      })
    }, [])

    const handleEditorUpdate = useCallback(() => {
      if (editorInstance) {
        setIsEditorEmpty(editorInstance.isEmpty)
      }
    }, [editorInstance])

    useEffect(() => {
      document.getElementById(LAST_MESSAGE_BUBBLE_ID)?.scrollIntoView()
    }, [messages])

    return (
      <Stack
        minH="70vh"
        h="min-content"
        w="100%"
        bg="white"
        pb={5}
        border="1px solid"
        borderColor="blue.200"
        borderRadius={8}
        {...props}
      >
        <Stack
          gap={5}
          overflowY="scroll"
          h="calc(70vh - 150px)"
          minH="300px"
          mr={2}
          py={10}
          css={scrollbarStyles}
          className="flex-grow"
        >
          {groupedMessages.length === 0 ? (
            <Text textAlign="center">
              Cette conversation ne contient pas encore de messages.
            </Text>
          ) : (
            groupedMessages.map((messageGroup, key) => {
              const isUserAuthor = messageGroup.authorUserId === user.id
              return (
                <Stack key={key}>
                  {(key === 0 ||
                    !isSameDate(
                      messageGroup.date,
                      groupedMessages[key - 1].date,
                    )) && (
                    <Text textAlign="center" color="gray.400" size="xs">
                      {dayjs(messageGroup.date).format("LL")}
                    </Text>
                  )}
                  <VStack alignItems="stretch" gap={1}>
                    {messageGroup.messages.map((m, index, messages) => (
                      <MessageBubble
                        id={
                          key === groupedMessages.length - 1 &&
                          index === messages.length - 1
                            ? LAST_MESSAGE_BUBBLE_ID
                            : undefined
                        }
                        isLast={index === messages.length - 1}
                        isFirst={index === 0}
                        key={m.date.getTime()}
                        message={m}
                        isUserAuthor={isUserAuthor}
                      />
                    ))}
                  </VStack>
                </Stack>
              )
            })
          )}
        </Stack>
        <Divider border="1px solid gray.400" />
        {isDisabled ? (
          <Text align="center">
            Votre dossier est clôturé. La messagerie est désactivée.
          </Text>
        ) : (
          <div className="flex flex-col sm:flex-row items-stretch p-4 space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="w-full sm:w-[85%] flex flex-col">
              <TiptapEditor
                onUpdate={handleEditorUpdate}
                onEditorReady={handleEditorReady}
              />
            </div>
            <div className="w-full sm:w-[15%] sm:flex sm:flex-col sm:justify-end">
              <button
                onClick={handleSendMessage}
                disabled={isPostingMessage || isEditorEmpty}
                className="w-full rounded-[20px] bg-[#008DEF] hover:bg-[#0070BE] text-white flex items-center justify-center px-4 py-3 sm:h-[calc(100%-40px)] disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 ease-in-out"
              >
                <div className="flex items-center justify-center gap-2">
                  {isPostingMessage ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <IconSend size={20} className="flex-shrink-0" />
                      <span className="text-sm font-medium">Envoyer</span>
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
      </Stack>
    )
  },
)

ChatInterface.displayName = "ChatInterface"
