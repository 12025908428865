import { useAuth } from "@clerk/clerk-react"
import { useCallback, useEffect, useMemo } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { ProfileData } from "@bleu/shared/questionnaires"
import { VerticalName, allVerticalNameList } from "@bleu/shared/verticals"

import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { logger } from "@bleu/front/utils/logger"
import { gtmTrack, useAmplitudeTrack } from "@bleu/front/utils/tracking"

import { DepistagesSelection } from "../components/funnelSteps/DepistagesSelection"
import { Payment } from "../components/funnelSteps/Payment"
import { PrescriptionRecap } from "../components/funnelSteps/PrescriptionsRecap"
import { ProfileQuestionnaire } from "../components/funnelSteps/ProfileQuestionnaire"
import { Questionnaires } from "../components/funnelSteps/Questionnaires"
import { Start } from "../components/funnelSteps/Start"
import {
  Steps,
  useDepistages,
  useFunnelEffects,
  useStepNavigation,
} from "../components/funnelSteps/hooks"
import { useAnswersSaving } from "../components/funnelSteps/hooks/useAnswersSaving"
import { useInitializeDepistages } from "../components/funnelSteps/hooks/useInitializeDepistages"
import {
  useFunnelStore,
  useProfileStore,
  useVerticalsStore,
} from "../lib/stores"

export const FunnelPage = () => {
  const {
    lastCompletedStep,
    setLastCompletedStep,
    setCurrentStep,
    currentStep,
    setSelectedVerticalsAndDepistages,
  } = useFunnelStore()
  const [searchParams] = useSearchParams()
  const { isSignedIn } = useAuth()
  const { profile, setProfile, setAcceptsMarketing } = useProfileStore()
  const {
    answers: allQuestionnairesAnswers,
    setAnswers: setAllQuestionnairesAnswers,
    reset: resetFunnelStore,
  } = useFunnelStore()
  const { selectedVerticals, setSelectedVerticals } = useVerticalsStore()
  const { handleNext, stepParam, goToPreviousStep } =
    useStepNavigation(searchParams)
  const { questionnairesToTake, prescribedDepistages } = useDepistages()
  const { handleSaveAnswers, medicalRecordCode } = useAnswersSaving()
  const { setOnBack } = useQuestionnaireLayoutStore()
  const navigate = useNavigate()
  const { track } = useAmplitudeTrack()
  useFunnelEffects(currentStep, handleNext)

  // Initialize depistages even when skipping the selection step
  useInitializeDepistages(profile!)

  useEffect(() => {
    logger.info("Step param", { stepParam })
    logger.info("Last completed step", { lastCompletedStep })

    if (lastCompletedStep === Steps.recap && !stepParam) {
      // User was redirected after sign up, move them to payment step
      logger.info("Moving user to payment step after sign up")
      setCurrentStep(Steps.payment)
      setLastCompletedStep(Steps.recap)
    } else if (stepParam) {
      const targetStep = Steps[stepParam as keyof typeof Steps]
      if (targetStep !== undefined) {
        setCurrentStep(targetStep)
      }
    }
  }, [lastCompletedStep, stepParam, setCurrentStep, setLastCompletedStep])

  const onProfileSubmit = useCallback(
    (profileData: ProfileData, acceptsMarketing: boolean) => {
      logger.info("Submitting profile data...")
      setProfile(profileData)
      setAcceptsMarketing(acceptsMarketing)
      track("Funnel - Profile completed", {
        profile: profileData,
        needsToSignUp: !isSignedIn,
      })
    },
    [setProfile, setAcceptsMarketing, isSignedIn, track],
  )

  const handlePrescriptionRecapSubmit = useCallback(() => {
    logger.info("Submitting prescription recap...")
    track("Funnel - Check Logged In Status", {
      needsToSignUp: !isSignedIn,
    })
    gtmTrack("recap_prescription")
    handleNext()
  }, [handleNext, isSignedIn, track])

  const renderStep = useMemo(() => {
    const trackingData = {
      step: Steps[currentStep],
      needsToSignUp: !isSignedIn,
    }

    logger.info(`Rendering step: ${Steps[currentStep]}`)
    switch (currentStep) {
      case Steps.start:
        track("Funnel - Start View", trackingData)
        return <Start />

      case Steps.profile:
        track("Funnel - Profile View", trackingData)
        return (
          <ProfileQuestionnaire
            profile={profile!}
            onSubmit={onProfileSubmit}
            handleNext={handleNext}
          />
        )

      case Steps.depistagesSelection:
        if (selectedVerticals[0] === VerticalName.GENERAL) {
          track("Funnel - Depistages Selection View", {
            ...trackingData,
            selectedVerticals,
          })
          return <DepistagesSelection profile={profile!} />
        }
        return null

      case Steps.questionnaires:
        track("Funnel - Questionnaires View", {
          ...trackingData,
          questionnaireCount: questionnairesToTake.length,
        })
        return (
          <Questionnaires
            questionnaires={questionnairesToTake}
            profile={profile!}
            onSubmit={setAllQuestionnairesAnswers}
          />
        )

      case Steps.recap:
        track("Funnel - Recap View", {
          ...trackingData,
          prescribedDepistagesCount: prescribedDepistages.length,
        })
        return (
          <PrescriptionRecap
            depistagesNames={prescribedDepistages}
            allAnswers={allQuestionnairesAnswers!}
            profile={profile!}
            onSubmit={handlePrescriptionRecapSubmit}
            onRestart={resetFunnelStore}
          />
        )

      case Steps.payment:
        track("Funnel - Payment View", {
          ...trackingData,
          selectedVerticals,
        })
        track("Funnel - Check Logged In Status", {
          needsToSignUp: !isSignedIn,
        })
        return (
          <Payment
            medicalRecordCode={medicalRecordCode}
            onSaveAnswers={handleSaveAnswers}
            verticalSelection={selectedVerticals}
          />
        )

      default:
        logger.error(`Unknown step: ${currentStep}`)
        return null
    }
  }, [
    currentStep,
    isSignedIn,
    profile,
    onProfileSubmit,
    questionnairesToTake,
    setAllQuestionnairesAnswers,
    allQuestionnairesAnswers,
    handlePrescriptionRecapSubmit,
    resetFunnelStore,
    medicalRecordCode,
    handleSaveAnswers,
    selectedVerticals,
    handleNext,
    prescribedDepistages,
    track,
  ])

  useEffect(() => {
    if (currentStep === Steps.questionnaires) {
      setOnBack({
        onClick: () => {
          const subStep = parseInt(searchParams.get("subStep") || "0")
          if (subStep > 0) {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.set("subStep", (subStep - 1).toString())
            window.history.pushState(null, "", `?${newSearchParams.toString()}`)
          } else {
            goToPreviousStep()
          }
        },
        text: "Back",
        isDisabled: false,
      })
    }
  }, [currentStep, setOnBack, searchParams, goToPreviousStep])

  useEffect(() => {
    const verticalParam = searchParams.get("vertical") as VerticalName | null
    const stepParam = searchParams.get("step")

    if (verticalParam && allVerticalNameList.includes(verticalParam)) {
      setSelectedVerticalsAndDepistages(verticalParam)
      setSelectedVerticals([verticalParam])
      track("Funnel - Verticals selected", {
        vertical: verticalParam,
      })

      // If step is explicitly set to 'start', respect that
      if (stepParam === "start") {
        setCurrentStep(Steps.start)
      } else {
        // Otherwise, proceed to profile step
        setCurrentStep(Steps.profile)
        navigate("/questionnaire?step=profile", { replace: true })
      }
    } else if (!verticalParam && !stepParam) {
      // Handle the case where the URL is just /questionnaire
      setSelectedVerticalsAndDepistages(VerticalName.GENERAL)
      setSelectedVerticals([VerticalName.GENERAL])
      setCurrentStep(Steps.start)
      track("Funnel - Verticals selected", {
        vertical: VerticalName.GENERAL,
      })
      navigate("/questionnaire?step=start&vertical=GENERAL", { replace: true })
    }
  }, [
    searchParams,
    setSelectedVerticalsAndDepistages,
    setSelectedVerticals,
    setCurrentStep,
    navigate,
    track,
  ])

  useEffect(() => {
    if (currentStep === Steps.depistagesSelection) {
      logger.info("Tracking verticals selected")
      track("Funnel - Verticals selected", {
        selectedVerticals,
        needsToSignUp: false,
      })
    }
  }, [currentStep, selectedVerticals, track])

  return renderStep
}
