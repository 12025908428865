import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react"
import { CheckIcon } from "@heroicons/react/24/solid"
import React, { useEffect, useRef } from "react"
import { Outlet, useSearchParams, Link } from "react-router-dom"

import { VerticalName } from "@bleu/shared/verticals"

import ReviewWrap from "@bleu/front/assets/icons/ReviewWrap"
import { UserMenu } from "@bleu/front/components/auth/UserMenu"
import { Logo } from "@bleu/front/components/layout/Logo"
import { StepIndicator } from "@bleu/front/components/layout/StepIndicator"
import { useFunnelStore, useVerticalsStore } from "@bleu/front/lib/stores"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { logger } from "@bleu/front/utils/logger"

import { Steps, resetAllStores, useStepNavigation } from "../funnelSteps/hooks"

export function QuestionnaireLayout() {
  const {
    header,
    title,
    cta,
    onBack,
    showBackButton,
    currentStep,
    totalSteps,
    setOnBack,
  } = useQuestionnaireLayoutStore()
  const [searchParams] = useSearchParams()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { goToPreviousStep: goToPreviousStepFunnelStore } = useFunnelStore()
  const { goToPreviousStep } = useStepNavigation(searchParams)
  const { selectedVerticals } = useVerticalsStore()
  const showDepistagesStep =
    selectedVerticals[0] === VerticalName.GENERAL ||
    selectedVerticals.length === 0

  const renderCount = useRef(0)

  useEffect(() => {
    renderCount.current += 1
    logger.info(
      `QuestionnaireLayout re-rendered (count: ${renderCount.current})`,
    )
  })

  useEffect(() => {
    logger.info(
      `CTA button state changed: ${cta ? `enabled=${!cta.isDisabled}, text="${cta.text}"` : "not visible"}`,
    )
  }, [cta])

  useEffect(() => {
    const currentStepParam = searchParams.get("step")
    if (currentStepParam !== Steps[Steps.questionnaires].toString()) {
      setOnBack({ onClick: goToPreviousStep, text: "Précédent" })
    }
  }, [searchParams, goToPreviousStep, setOnBack])

  const handleBack = () => {
    logger.info("Going back to previous step")
    goToPreviousStepFunnelStore()
    goToPreviousStep()
  }

  return (
    <Box
      bgRepeat="no-repeat"
      bgImage="/img/dots-background-corner.webp"
      bgSize="cover"
    >
      <Flex
        w="100%"
        h={["80px", "88px"]}
        px={5}
        py={4}
        marginBottom={5}
        alignItems="center"
        position="relative"
      >
        <Flex
          justifyContent="space-between"
          w="100%"
          position="absolute"
          left={0}
          px={5}
        >
          <Link to="/" onClick={resetAllStores}>
            <Logo />
          </Link>
          <UserMenu />
        </Flex>
        {!isMobile && (
          <Flex justifyContent="center" w="100%">
            <ReviewWrap />
          </Flex>
        )}
      </Flex>
      <Container maxW="container.xl" mb={0} px={5}>
        <Flex
          flexDirection="column"
          minHeight={isMobile ? "calc(100vh - 32px)" : "calc(100vh - 88px)"}
          width="100%"
          position="relative"
        >
          <Box as="main" flexGrow={1} overflowY="auto">
            <VStack spacing={3} align="stretch">
              {header && (
                <Flex flexDirection="column" gap={4} mb={4}>
                  {!isMobile && (
                    <StepIndicator
                      currentStep={currentStep}
                      totalSteps={totalSteps}
                      showDepistagesStep={showDepistagesStep}
                      completedStepIcon={
                        <Icon as={CheckIcon} color="blue.400" />
                      }
                    />
                  )}
                  {isMobile && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      bg="blue.200"
                      p={4}
                      borderRadius="md"
                      flexDirection="column"
                    >
                      <Flex
                        justifyContent="space-between"
                        width="100%"
                        alignItems="center"
                      >
                        {title && (
                          <Heading as="h2" size="lg">
                            {typeof title === "string" ? (
                              title
                            ) : (
                              <>
                                {title.text
                                  .split(title.highlight ?? "")
                                  .map((part, index, array) => (
                                    <React.Fragment key={index}>
                                      {part}
                                      {index < array.length - 1 && (
                                        <Text as="span" color="blue.400">
                                          {title.highlight}
                                        </Text>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </>
                            )}
                          </Heading>
                        )}
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="navy.400"
                          ml={4}
                          border="1px solid"
                          borderColor="blue.400"
                          borderRadius="md"
                          p={2}
                          textAlign="center"
                        >
                          {currentStep + 1}/{totalSteps}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}
              <Outlet />
            </VStack>
          </Box>
        </Flex>
      </Container>
      <Box
        as="footer"
        bg="blue.200"
        py={4}
        position="sticky"
        bottom={0}
        width="100%"
        boxShadow="0 -1px 0.5px blue.400"
      >
        <Container maxWidth="container.xl">
          <Flex justifyContent="space-between" alignItems="center">
            {showBackButton && (
              <Button
                variant="outline"
                onClick={onBack?.onClick ?? handleBack}
                colorScheme="blue"
                size="lg"
              >
                Précédent
              </Button>
            )}
            <Box flexGrow={1} />
            {cta && (
              <Button
                onClick={() => {
                  if (!cta.isDisabled) {
                    logger.info(`CTA button clicked: ${cta.text}`)
                    cta.onClick()
                  } else {
                    logger.info(`CTA button clicked but disabled: ${cta.text}`)
                  }
                }}
                isDisabled={cta.isDisabled}
                colorScheme="blue"
                size="lg"
              >
                {cta.text}
              </Button>
            )}
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default QuestionnaireLayout
