import { Divider, HStack, Icon, Text, VStack } from "@chakra-ui/react"
import { IconUser } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import type { MedicalRecordResponses } from "@bleu/types/endpoints"

import {
  DepistagesLongNames,
  getVerticalNameForDepistageName,
  type DepistageName,
} from "@bleu/shared/depistages"
import {
  QuestionTypes,
  profileQuestionnaire,
  type ProfileQuestionnaireShape,
  type QuestionnaireShape,
} from "@bleu/shared/questionnaires"

import { colors } from "@bleu/front/assets/theme/colors"
import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { FreeTextQuestion } from "@bleu/front/components/forms/FreeTextQuestion"
import {
  MultiSelectQuestion,
  type MultiSelectOption,
} from "@bleu/front/components/forms/MultiSelectQuestion"
import { NumberQuestion } from "@bleu/front/components/forms/NumberQuestion"
import {
  SingleSelectQuestion,
  type SingleSelectOption,
} from "@bleu/front/components/forms/SingleSelectQuestion"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { useMedicalRecordAnswersQuery } from "@bleu/front/queries/medicalRecords"
import { useAmplitudeTrack } from "@bleu/front/utils/tracking"

interface DepistageCardProps {
  depistageName: DepistageName
}

const DepistageCard = ({ depistageName }: DepistageCardProps) => {
  const verticalName = getVerticalNameForDepistageName(depistageName)
  return (
    <HStack
      flexShrink={0}
      px={8}
      py={6}
      borderRadius={20}
      background={verticalsTheme[verticalName].verticalButtonGradient}
      position="relative"
    >
      <Icon
        as={verticalsTheme[verticalName].icon}
        boxSize={5}
        color={verticalsTheme[verticalName].color}
      />
      <Text fontWeight={600} textAlign="left">
        {DepistagesLongNames[depistageName]}
      </Text>
    </HStack>
  )
}

interface ReadOnlyQuestionProps {
  question:
    | QuestionnaireShape["questions"][number]
    | ProfileQuestionnaireShape["questions"][number]
  value: string | boolean | string[] | number
}
const ReadOnlyQuestion = ({ question, value }: ReadOnlyQuestionProps) => {
  switch (question.type) {
    case QuestionTypes.SingleSelect:
      return (
        <SingleSelectQuestion
          title={question.title}
          options={question.options as SingleSelectOption[]}
          isReadOnly={true}
          value={value as string | boolean}
          onChange={() => {}}
        />
      )
    case QuestionTypes.MultiSelect:
      return (
        <MultiSelectQuestion
          isReadOnly={true}
          title={question.title}
          subtitle={question.subtitle}
          options={question.options as MultiSelectOption[]}
          values={value as string[]}
          onChange={() => {}}
        />
      )
    case QuestionTypes.FreeText:
      return (
        <>
          <FreeTextQuestion
            onChange={() => {}}
            isReadOnly={true}
            title={question.title}
            value={value as string}
          />
        </>
      )
    case QuestionTypes.Date:
      return (
        <FreeTextQuestion
          onChange={() => {}}
          isReadOnly={true}
          title={question.title}
          value={dayjs(value as string).format("DD/MM/YYYY")}
        />
      )
    case QuestionTypes.Number:
      return (
        <NumberQuestion
          onChange={() => {}}
          isReadOnly={true}
          title={question.title}
          {...("unit" in question && { unit: question.unit })}
          value={value as number}
        />
      )
  }
}

export const AnswersPage = () => {
  const { medicalRecordId } = useParams()

  if (!medicalRecordId) {
    throw new Error("No medical record id provided")
  }

  const medicalRecordPageData = useMedicalRecordAnswersQuery(medicalRecordId)

  return (
    <QueryWrapper query={medicalRecordPageData}>
      {({ data }) => (
        <_AnswersPage data={data} medicalRecordId={medicalRecordId} />
      )}
    </QueryWrapper>
  )
}

const isNullishOrEmpty = (
  value: unknown,
): value is undefined | null | "" | [] =>
  _.isNull(value) || _.isUndefined(value) || value === ""

interface Props {
  data: MedicalRecordResponses.MedicalRecordAnswersPageData
  medicalRecordId: string
}

const _AnswersPage = ({ data, medicalRecordId }: Props) => {
  const navigate = useNavigate()
  const { setHeader, setTitle, setCta, setShowBackButton } =
    useQuestionnaireLayoutStore()
  const { track } = useAmplitudeTrack()

  useEffect(() => {
    track("Questionnaire Answers Viewed", {})
  }, [track])

  useEffect(() => {
    setHeader({ pageName: "Mes réponses aux questions" })
    setTitle("Mes réponses aux questions")
    setShowBackButton(true)
    setCta({
      onClick: () => navigate(`/prescriptions/${medicalRecordId}`),
      text: "Retour aux prescriptions",
      isDisabled: false,
    })
  }, [
    setHeader,
    setTitle,
    setShowBackButton,
    setCta,
    navigate,
    medicalRecordId,
  ])

  const { profile, questionnaireAnswer } = data
  const { prescribedDepistages } = questionnaireAnswer

  const richDepistages = prescribedDepistages
    .filter((depistage) => depistage.questionnaire !== undefined)
    .map((depistage) => {
      const verticalName = getVerticalNameForDepistageName(depistage.name)

      return {
        ...depistage,
        // @ts-expect-error this has been filtered above
        answers: questionnaireAnswer[depistage.name],
        icon: verticalsTheme[verticalName].icon,
        color: verticalsTheme[verticalName].color,
      }
    })

  return (
    <VStack gap={8} alignItems="stretch">
      <Text size="sm">
        Ceci est un rappel de vos réponses pour le dossier en cours. Elles ne
        peuvent être modifiées ici.
      </Text>
      <HStack
        flexShrink={0}
        px={8}
        py={6}
        borderRadius={20}
        background={verticalsTheme["general"].verticalButtonGradient}
        position="relative"
      >
        <IconUser size={24} color={colors.blue[500]} />
        <Text fontWeight={600} textAlign="left">
          Votre profil
        </Text>
      </HStack>
      <VStack
        gap={10}
        paddingBlockEnd={20}
        alignItems="stretch"
        divider={<Divider border="1px solid gray.300" />}
      >
        <VStack gap={8} alignItems="stretch">
          {profileQuestionnaire.questions.map((question) => {
            const answer = profile[question.id as keyof typeof profile]
            return (
              !isNullishOrEmpty(answer) && (
                <ReadOnlyQuestion
                  key={question.id}
                  question={question}
                  value={answer}
                />
              )
            )
          })}
        </VStack>
        {richDepistages.map(({ questionnaire, name, answers }) => (
          <VStack key={name} gap={8} alignItems="stretch">
            <DepistageCard depistageName={name} />
            {questionnaire?.questions.map((question) => {
              const answer = answers[question.id]

              return (
                !isNullishOrEmpty(answer) && (
                  <ReadOnlyQuestion
                    key={question.id}
                    question={question}
                    value={answers[question.id]}
                  />
                )
              )
            })}
          </VStack>
        ))}
      </VStack>
    </VStack>
  )
}
