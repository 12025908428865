import { Flex, Heading, VStack, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"

interface QuestionWrapperProps {
  title: ReactNode
  subtitle?: ReactNode
  children: React.ReactNode
}

export const QuestionWrapper: React.FC<QuestionWrapperProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Flex gap={6} direction="column" width="100%" maxWidth="600px" mx="auto">
      <VStack gap={2} align="center">
        <Heading size="lg" textAlign="center">
          {title}
        </Heading>
        {subtitle && (
          <Text textAlign="center" fontSize="sm">
            {subtitle}
          </Text>
        )}
      </VStack>
      {children}
    </Flex>
  )
}
