/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, HStack, Heading, Icon, VStack } from "@chakra-ui/react"
import type { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import React, { useEffect, useMemo, useCallback } from "react"

import {
  DepistagesLongNames,
  getVerticalNameForDepistageName,
} from "@bleu/shared/depistages"
import {
  type QuestionnaireShape,
  type ProfileQuestionnaireShape,
  type ProfileData,
  AllQuestionnairesAnswers,
  QuestionnaireName,
  QuestionTypes,
} from "@bleu/shared/questionnaires"

import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { InfoBox } from "@bleu/front/components/InfoBox"
import { DateQuestion } from "@bleu/front/components/forms/DateQuestion"
import { FreeTextQuestion } from "@bleu/front/components/forms/FreeTextQuestion"
import { MultiSelectQuestion } from "@bleu/front/components/forms/MultiSelectQuestion"
import { NumberQuestion } from "@bleu/front/components/forms/NumberQuestion"
import {
  SingleSelectOption,
  SingleSelectQuestion,
} from "@bleu/front/components/forms/SingleSelectQuestion"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"

interface Props {
  question:
    | QuestionnaireShape["questions"][number]
    | ProfileQuestionnaireShape["questions"][number]
  questionnaireName: QuestionnaireName | "profile"
  form: UseFormReturnType<AllQuestionnairesAnswers>
  profile: ProfileData
}
export const Question = ({
  question,
  questionnaireName,
  form,
  profile,
}: Props) => {
  const pathToQuestion = `${questionnaireName}.${question.id}`

  const uniqueValues = useMemo(
    () =>
      "options" in question
        ? question.options
            .filter((option) => option.unique)
            .map((option) => option.value)
        : undefined,
    [question],
  )

  switch (question.type) {
    case QuestionTypes.SingleSelect: {
      return (
        <SingleSelectQuestion
          title={question.title}
          subtitle={question.subtitle}
          options={question.options as SingleSelectOption[]}
          key={pathToQuestion}
          {...form.getInputProps(pathToQuestion)}
        />
      )
    }
    case QuestionTypes.MultiSelect: {
      const options = question.options.filter(
        (option) =>
          option.shouldShow === undefined ||
          option.shouldShow(
            // @ts-expect-error too much generics. I'm lost TODO
            form.values[questionnaireName],
            profile,
          ),
      )
      return (
        <MultiSelectQuestion
          title={question.title}
          subtitle={question.subtitle}
          options={options}
          key={pathToQuestion}
          values={form.getInputProps(pathToQuestion).value}
          onChange={(value, isUnique) => {
            // @ts-expect-error too much generics. I'm lost
            const values = form.values[questionnaireName][
              question.id
            ] as string[]

            if (values.includes(value)) {
              _.pull(values, value)
            } else if (
              // toggle unique values
              isUnique ||
              (values.length === 1 && uniqueValues?.includes(values[0]))
            ) {
              form.setFieldValue(pathToQuestion, [value])
              return
            } else {
              values.push(value)
            }

            form.setFieldValue(pathToQuestion, [...values])
          }}
        />
      )
    }
    case QuestionTypes.FreeText: {
      return (
        <>
          <FreeTextQuestion
            title={question.title}
            key={pathToQuestion}
            {...form.getInputProps(pathToQuestion)}
          />
        </>
      )
    }
    case QuestionTypes.Number: {
      return (
        <NumberQuestion
          key={pathToQuestion}
          title={question.title}
          unit={question.unit}
          {...form.getInputProps(pathToQuestion)}
        />
      )
    }
    case QuestionTypes.Date: {
      return (
        <DateQuestion
          key={pathToQuestion}
          title={question.title}
          {...form.getInputProps(pathToQuestion)}
        />
      )
    }
  }
}

export interface QuestionnaireProps {
  questionnaire: QuestionnaireShape
  onEmptyQuestionnaire: () => void
  form: UseFormReturnType<{ profile?: ProfileData } & AllQuestionnairesAnswers>
  profile: ProfileData
  onNext: () => void
  onPrevious: () => void
}

// Memoize the Question component
const MemoizedQuestion = React.memo(Question)

export function Questionnaire({
  questionnaire,
  profile,
  form,
  onEmptyQuestionnaire,
  onNext,
  onPrevious,
}: QuestionnaireProps) {
  const { setHeader, setTitle, setCta, setShowBackButton, setOnBack } =
    useQuestionnaireLayoutStore()

  const questions = useMemo(() => {
    return questionnaire.questions.filter((question) =>
      question.shouldShow(
        // @ts-expect-error TODO
        form.values[questionnaire.name as QuestionnaireName],
        profile,
      ),
    )
  }, [questionnaire, form.values, profile])

  const verticalName = useMemo(
    () => getVerticalNameForDepistageName(questionnaire.name),
    [questionnaire.name],
  )
  const depistageLongName = useMemo(
    () => DepistagesLongNames[questionnaire.name],
    [questionnaire.name],
  )
  const verticalTheme = useMemo(
    () => verticalsTheme[verticalName],
    [verticalName],
  )

  const isFormValid = useMemo(() => {
    return form.isValid()
  }, [form.isValid])

  const handleEmptyQuestionnaire = useCallback(() => {
    onEmptyQuestionnaire()
  }, [onEmptyQuestionnaire])

  useEffect(() => {
    if (questions.length === 0) {
      handleEmptyQuestionnaire()
    }
  }, [questions, handleEmptyQuestionnaire])

  useEffect(() => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [depistageLongName])

  useEffect(() => {
    setHeader({
      pageName: depistageLongName,
      progress: 60,
    })
    setTitle({
      text: "Questions relatives à votre dépistage",
      highlight: "Questions relatives",
    })
    setShowBackButton(true)
    setCta({
      onClick: onNext,
      isDisabled: !isFormValid,
      text: "Continuer",
    })
    setOnBack({
      onClick: onPrevious,
      text: "Précédent",
    })
  }, [isFormValid, depistageLongName])

  return (
    <VStack gap={8} alignItems="stretch" pt={4} pb={8}>
      <HStack gap={4}>
        <Icon as={verticalTheme.icon} color={verticalTheme.color} boxSize={5} />
        <Heading size="xl">{depistageLongName}</Heading>
      </HStack>
      {questions.map((question) => (
        <React.Fragment key={question.id}>
          <Divider borderColor="gray.300" />
          <MemoizedQuestion
            question={question}
            questionnaireName={questionnaire.name}
            form={form}
            profile={profile}
          />
          {question.infoBox && (
            <InfoBox
              title={question.infoBox.title}
              message={question.infoBox.message}
            />
          )}
        </React.Fragment>
      ))}
    </VStack>
  )
}
